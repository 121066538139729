import axios from "axios";
import env from '../env/env.json';

const apiRefresh = () => {
  let apiId = localStorage.getItem("apiId");
  let refreshToken = localStorage.getItem("refreshToken");
  let xTimestamp = localStorage.getItem("xTimestamp");
  let xSign = localStorage.getItem("xSign");

  const apiClient = axios.create({
    baseURL: env.baseURL + "refresh",
    timeout: 60000,
    headers: {
      "Content-Type": "application/json",
      "API-ID": apiId,
      "X-sign": xSign,
      "X-Timestamp": xTimestamp,
      Authorization: "Bearer " + refreshToken,
    },
  });

  return apiClient;
};

export default apiRefresh;
