import React from "react";

import "./ndflWarning.css";

const NDFLWarning = () => {
  return (
    <div className={`ndfl__wrapper-aside `}>
      <div className="ndfl__aside-content">
        <div className="ndfl__container-image">
          <img src={require("../../assets/icons/ndfl.jpg")} alt='image' className="ndfl__aside_image"/>
        </div>
        <div>
          <h2 className="ndfl__aside_title">Что такое НДФЛ и почему его нужно оплатить?</h2>
          <p className="ndfl__aside_description">НДФЛ — это налог на доходы физических лиц. Взимается не только с заработной платы, но и с доходов, полученных через программы лояльности и других источников в денежном или натуральном виде. Зачем его платить? НДФЛ нужен для финансирования различных государственных нужд — медицины, образования, обороны и других сфер. Поэтому, уплачивая НДФЛ, вы помогаете обществу и участвуете в формировании бюджета страны.</p>
        </div>
      </div>
    </div>
  )
}

export default NDFLWarning