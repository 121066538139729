import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ax from "../../utils/ax";
import { calculateTotalSum } from "../../utils/helpers";

const initialState = {
  items: [],
  total: 0,
};

export const getBasket = createAsyncThunk(
  "basket/get",
  async () => {
    try {
      const {data} = await ax().post('basket/get', {})
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const postBasket = createAsyncThunk(
  "basket/update",
  async (epsData) => {
    try {
      const {data} = await ax().post('basket/update', epsData)
      return data;
    } catch (error) {
      return error;
    }
  }
);

const calculateTotalPrice = (items) => items.reduce((total, item) => +total + +item.total, 0);

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart(state, action) {
      action.payload.eps.forEach(newItem => {
        const existingItemIndex = state.items.findIndex(item => 
          item.guid === newItem.guid && item.id === newItem.id
        );
        if (existingItemIndex !== -1) {
          state.items[existingItemIndex] = {
            ...state.items[existingItemIndex],
            ...newItem,
            reserve: state.items[existingItemIndex].reserve,
            total: calculateTotalSum(newItem),
            user_discount: newItem.data.user_discount,
            user_markup: newItem.data.user_markup
          };
        } else {
          state.items.push({
            ...newItem,
            total: calculateTotalSum(newItem),
            user_discount: newItem.data.user_discount,
            user_markup: newItem.data.user_markup
          });
        }
      });
      state.total = calculateTotalPrice(state.items) || 0;
    },
    clearCart(state, action) {
      state.items = [];
      state.total = 0;
    },
    deleteFromCart(state, action) {
      let index = state.items.findIndex((i) => i.id == action.payload.item.id);
      state.items.splice(index, 1);
      state.total = state.total - action.payload.item.total;
    },
    cartPlus(state, action) {
      const { item } = action.payload;
      const index = state.items.findIndex(i => i.id === item.id);
      state.items[index] = {
        ...state.items[index],
        quantity: (state.items[index].quantity || 0) + 1,
        total_write_off_amount: (state.items[index].total_write_off_amount || 0) + (state.items[index].write_off_amount || 0),
      };
      state.items[index].total = calculateTotalSum(state.items[index]);
      state.total = calculateTotalPrice(state.items);
    },
    cartMinus(state, action) {
      let iCopy = [...state.items];
      if (action.payload.item.quantity === 1) {
        let index = state.items.findIndex(
          (i) => i.id == action.payload.item.id
        );
        state.items.splice(index, 1);
        state.total = calculateTotalPrice(state.items);
      } else {
        let i = iCopy.findIndex((j) => j.id === action.payload.item.id);
        let item = iCopy[i];
        item.quantity -= 1;
        item.total = calculateTotalSum(item);
        state.items = iCopy;
        state.total = calculateTotalPrice(state.items);
      }
    },
  },
});

export const { addToCart, clearCart, deleteFromCart, cartPlus, cartMinus } =
  cartSlice.actions;

export default cartSlice.reducer;
