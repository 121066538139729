import React from "react";
import { useNavigate } from "react-router-dom";

import { closeModal } from "../../utils/helpers";

import './GoBack.css';

const GoBack = () => {
  const navigate = useNavigate();
  
  const handleClickGoBack = () => {
    navigate("/");
    document.body.classList.remove('modal-open');
    closeModal();
  };

  return (
    <a className="go-back" onClick={handleClickGoBack}>
      <img alt="img"
        src={require("../../assets/icons/goBack.png")}
        className="go-back__image"
      />
      Витрина призов
    </a>
  );
};

export default GoBack;

