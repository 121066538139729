import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import MaskedInput from "react-text-mask";

import { ndflUpdate, updateUser } from "../../actions/widgetActions";
import ErrorModal from "../ErrorModal/ErrorModal";
import Button from "../Button/Button";
import { cleanPhoneNumber, reverseDate, getScrollbarWidth, openModal, validateEmail } from "../../utils/helpers";
import { EditProfileSend, getProfile } from "../../redux/reducers/userReducer";

import "./profile_form.css";

const ProfileForm = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [first_name, setFirstName] = useState(user.first_name);
  const [patronymic, setMiddleName] = useState(user.patronymic);
  const [last_name, setLastName] = useState(user.last_name);
  const [date_of_birth, setBirthDate] = useState(user.date_of_birth);
  const [phone, setPhone] = useState(user.phone);
  const [email, setEmail] = useState(user.email);
  const [gender, setGender] = useState(user.gender);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [patronymicError, setPatronymicError] = useState(false);
  const [genderError, setGenderError] = useState(false);

  const [messages, setMessages] = useState([]);

  const scrollWidth = getScrollbarWidth();

  const validErrors = () => {
    const checks = [
      checkDateError(date_of_birth),
      checkEmailError(email),
      checkPhoneError(phone),
      checkNameError(last_name, setLastNameError),
      checkNameError(first_name, setFirstNameError),
      checkNameError(patronymic, setPatronymicError),
      checkGenderError(gender, setGenderError)
    ];

    const hasErrors = checks.some(result => result);

    return hasErrors;
  };

  const handleClickPostData = () => {
    const valid = validErrors();

    if (!valid) {
      dispatch(EditProfileSend({
        first_name,
        last_name,
        patronymic,
        date_of_birth: reverseDate(date_of_birth),
        phone,
        email,
        gender,
      }))
        .then(res => {
          if (res?.payload?.success) {
            const data = res.meta.arg;

            dispatch(updateUser({...data}));
            
            dispatch(ndflUpdate({
              first_name,
              last_name,
              patronymic,
              date_of_birth: reverseDate(date_of_birth)
            }));
  
            setMessages([
              {
                title: "Данные успешно сохранены",
                message: "",
                firstColor: "#000",
                secondColor: "#000",
              },
            ]);
          } else {
            setMessages([
              {
                title: "Ошибка при сохранении данных",
                message: "",
                firstColor: "#000",
                secondColor: "#000",
              },
            ]);
          }
          openModal(scrollWidth);
        })
        .catch(error => {
          setMessages([
            {
              title: "Ошибка при сохранении данных",
              message: "",
              firstColor: "#000",
              secondColor: "#000",
            },
          ]);
          openModal(scrollWidth);
        });
    };
  }

  const renderInputFieldMask = (title, placeholder, value, onChange, mask, error, errorValue) => {
    return (
      <div className="profile-form__wrapper">
        <b className="profile-form__field-header">{title}</b>
        <MaskedInput
          type={'tel'}
          value={value}
          placeholder={placeholder}
          className={`input ${errorValue ? 'input_warning' : ''}`}
          mask={mask}
          onChange={onChange}
        />
        {errorValue ? error(value) : <br />}
      </div>
    );
  };

  const renderInputField = (title, placeholder, value, onChange, onKeyPress, error, errorValue) => {
    return (
      <div className="profile-form__wrapper">
        <b className="profile-form__field-header">{title}</b>
        <input
          value={value}
          placeholder={placeholder}
          onKeyPress={() => onKeyPress && onKeyPress()}
          className={`input ${errorValue ? 'input_warning' : ''}`}
          onChange={onChange}
        />
        {errorValue ? error(value) : <br />}
      </div>
    );
  };

  const checkNameError = (value, setValue) => {
    if (!value.match(/^[А-Яа-я]+$/g) || value.length < 2 || value.length > 30) {
      setValue(true);
      return true;
    } else {
      setValue(false);
      return false;
    }
  };

  const renderInputNameError = (value) => {
    return (
      !value.match(/^[А-Яа-я]+$/g)
        ? <b className="profile-form__warning">Использование не доступимых символов или цифр</b>
        : value.length < 2
          ? <b className="profile-form__warning">Мало символов</b>
          : value.length > 30
            ? <b className="profile-form__warning">Много символов</b>
            : <br/>
    );
  };

  const checkPhoneError = (value) => {
    const currentValue = value || phone;

    if (!value || currentValue.includes('_')) {
      setPhoneError(true);
      return true;
    } else {
      setPhoneError(false);
      return false;
    }
  };

  const renderInputPhoneError = (value) => {
    return (
      !value || value.includes('_')
        ? <b className="profile-form__warning">Не верный номер телефона</b>
        : <br/>
    );
  };

  const checkDateError = (value) => {
    const currentValue = value || date_of_birth;

    if (!value || currentValue.includes('_')) {
      setDateError(true);
      return true;
    } else {
      setDateError(false);
      return false;
    }
  };

  const renderInputDateError = (value) => {
    return (
      !value || value.includes('_')
        ? <b className="profile-form__warning">Дата не заполнена</b>
        : <br/>
    );
  };

  const checkEmailError = (value) => {
    const currentValue = value || email;
    const error = validateEmail(currentValue);
    if (!!error) {
      setEmailError(!!error);
      return true;
    } else {
      setEmailError(!!error);
      return false;
    }
    
  };

  const renderInputEmailError = (value) => {
    const error = validateEmail(value);
    return error ? <b className="profile-form__warning">{error}</b> : <br/>;
  };

  const checkGenderError = (value, setValue) => {
    if (!value) {
      setValue(true);
      return true;
    } else {
      setValue(false);
      return false;
    }
  };

  const renderGenderError = () => {
    return genderError ? <b className="profile-form__warning">Пол не выбран</b> : <br/>;
  };

  const renderGenderSelect = () => {
    return (
      <div className="profile-form__wrapper profile-form__wrapper-gender">
        <b className="profile-form__field-header">Пол</b>
        <div className="profile-form__select">
          <p onClick={() => setGender("муж")}>
            <input
              type="radio"
              value="муж"
              name="gender"
              checked={gender === "муж"}
              className="profile-form__radio-input"
              onChange={(e) => setGender(e.currentTarget.value)}
            />
            <b
              className={`
                profile-form__variant 
                ${gender === 'муж' ? 'profile-form__variant_active' : ''}
              `}>
              Мужской
            </b>
          </p>
          <p onClick={() => setGender("жен")} className="profile-form__gender">
            <input
              type="radio"
              value="жен"
              name="gender"
              className="profile-form__radio-input"
              onChange={(e) => setGender(e.currentTarget.value)}
              checked={gender === "жен"}
            />
            <b
              className={`
                profile-form__variant 
                ${gender === 'жен' ? 'profile-form__variant_active' : ''}
              `}>
              {" "}
              Женский{" "}
            </b>
          </p>
        </div>
        {renderGenderError()}
      </div>
    );
  };

  const renderSend = () => {
    return (
      <Button
        customClass="button_save"
        onClick={handleClickPostData}
        title="Сохранить"
      />
    );
  };

  useEffect(() => {
    dispatch(getProfile()).then(res => {
      const data = res?.payload?.data;
      dispatch(updateUser({ ...data, date_of_birth: reverseDate(data.date_of_birth)}));
    }).catch((error) => {
      console.log(error)
    })
  }, [dispatch])

  if (isMobile) {
    return (
      <div className="profile-form__container">
        <ErrorModal messages={messages} setMessages={setMessages} />
        {renderInputField(
          "Фамилия", 
          "", 
          last_name, 
          (t) => {
            setLastName(t.target.value);
            checkNameError(t.target.value, setLastNameError);
          },
          () => {},
          renderInputNameError,
          lastNameError
        )}
        {renderInputField(
          "Имя", 
          "", 
          first_name, (t) => {
            setFirstName(t.target.value);
            checkNameError(t.target.value, setFirstNameError);
          },
          () => {},
          renderInputNameError,
          firstNameError,
        )}
        {renderInputField(
          "Отчество", 
          "", 
          patronymic, 
          (t) => {
            setMiddleName(t.target.value); 
            checkNameError(t.target.value, setPatronymicError);
          },
          () => {},
          renderInputNameError,
          patronymicError
        )}
        {renderInputFieldMask(
          "Дата рождения",
          "",
          date_of_birth,
          (t) => {
            setBirthDate(t.target.value); 
            checkDateError(t.target.value);
          },
          [/[0-3]/, /\d/, '-', /[0-1]/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
          renderInputDateError,
          dateError
        )}
        {renderInputFieldMask(
          "Номер телефона",
          "",
          phone,
          (t) => {
            setPhone(cleanPhoneNumber(t.target.value));
            checkPhoneError(t.target.value);
          },
          ["+", "7", " ", /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/],
          renderInputPhoneError,
          phoneError,
        )}
        {renderInputField(
          "Email", 
          "", 
          email, 
          (t) => {
            setEmail(t.target.value);
            checkEmailError(t.target.value);
          },
          () => {},
          renderInputEmailError,
          emailError
        )}
        {renderGenderSelect()}
        {renderSend()}
      </div>
    );
  }

  return (
    <div className="profile-form__container">
      <ErrorModal messages={messages} setMessages={setMessages} />
      <div>
        {renderInputField(
          "Фамилия",
          "",
          last_name,
          (t) => {
            setLastName(t.target.value);
            checkNameError(t.target.value, setLastNameError);
          },
          () => {},
          renderInputNameError,
          lastNameError
        )}
        {renderInputField(
          "Имя",
          "",
          first_name,
          (t) => {
            setFirstName(t.target.value);
            checkNameError(t.target.value, setFirstNameError);
          },
          () => {},
          renderInputNameError,
          firstNameError,
        )}
        {renderInputField(
          "Отчество",
          "",
          patronymic,
          (t) => {
            setMiddleName(t.target.value);
            checkNameError(t.target.value, setPatronymicError);
          },
          () => {},
          renderInputNameError,
          patronymicError
        )}
        {renderInputFieldMask(
          "Дата рождения",
          "",
          date_of_birth,
          (t) => {
            setBirthDate(t.target.value);
            checkDateError(t.target.value);
          },
          [/[0-3]/, /\d/, '-', /[0-1]/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
          renderInputDateError,
          dateError
        )}
        {renderSend()}
      </div>
      <div>
        {renderInputFieldMask(
          "Номер телефона",
          "",
          phone,
          (t) => {
            setPhone(cleanPhoneNumber(t.target.value));
            checkPhoneError(t.target.value);
          },
          ["+", "7", " ", /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/],
          renderInputPhoneError,
          phoneError,
        )}
        {renderInputField(
          "Email",
          "",
          email,
          (t) => {
            setEmail(t.target.value);
            checkEmailError(t.target.value);
          },
          () => {},
          renderInputEmailError,
          emailError
        )}
        {renderGenderSelect()}
      </div>
    </div>
  );
};

export default ProfileForm;
