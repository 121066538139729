import { useSelector, useDispatch } from "react-redux";
import React from "react";
import { useMediaQuery } from "react-responsive";
import moment from "moment";

import Button from "../Button/Button";
import { updateUser, createOrder, clearCart } from "../../actions/widgetActions";
import { calculateTotalSum, closeModal, getScrollbarWidth, openModal } from "../../utils/helpers";
import { postCreateOrder } from "../../redux/reducers/ordersReducer";
import { getProfileBalance } from "../../redux/reducers/userReducer";

import "./order_process.css";

const OrderProcess = (props) => {
  const {
    items,
    setShowOrderComplete,
    setShowOrderProcess,
    setItemsCopy,
    setTotalCopy,
    setGetError,
  } = props;
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const user = useSelector((state) => state.user);
  const ndfl = useSelector((state) => state.ndfl);
  const listLang = useSelector((state) => state.listLang);
  const cart = useSelector((state) => state.cart);
  const orders = useSelector((state) => state.orders);
  const dispatch = useDispatch();

  const scrollWidth = getScrollbarWidth();

  const closePopup = () => {
    setShowOrderProcess(false);
    closeModal();
  };

  const handleCreateOrder = () => {
    if (ndfl.status === 'confirmed') {
      dispatch(postCreateOrder()).then(res => {
        if (res.payload.success) {
          openModal(scrollWidth);
          setItemsCopy(cart.items);
          setTotalCopy(cart.total);
          closePopup();
          setShowOrderComplete(true);

          let date = new moment(new Date()).format("DD.MM.YYYY");
          const orderItems = cart.items.map((item) => ({
            ...item,
            status: 'pending'
          }));
          
          let order = {
            id: orders.items.length + 1,
            date,
            price: parseInt(cart.total),
            type: "prize",
            items: orderItems,
          };

          dispatch(createOrder({ item: order }));
          dispatch(clearCart());
          dispatch(getProfileBalance()).then(res => {
            const data = res.payload.data;
            dispatch(updateUser({ points: data?.balance}));
          });
        } else {
          const error = res.payload.response.data.error
          setGetError([
            {
              title: res.payload.response.data.message || "Ошибка при отправки данных",
              message: error || '',
              firstColor: "#000",
              secondColor: "#000",
            },
          ])
          closePopup();
        }
      });
    }
  };

  if (isMobile) {
    return (
      <div className="order-process__background">
        <div className="order-process__close-container order-process__close-container_mobile">
          <p onClick={closePopup} className="order-process__close-wrapper">
            <img
              alt="img"
              src={require("../../assets/icons/close.png")}
              className="order-process__close-icon"
            />
          </p>
        </div>
        <div className={'order-process__container'}>
          <b className="order-process__message-title">
            Вы оформляете заказ <br /> на сумму:{" "}
            <b className="order-process__message_bold">{cart.total + ' ' + listLang.symbol}</b>
          </b>

          <p className="order-process__message-text">
            Выбранные сертификаты будут <br /> отправлены на адрес электронной
            почты{" "}
            <a className="order-process__message-link" href="email_fhrfsgs@mail.ru">email_fhrfsgs@mail.ru</a>{" "}
          </p>
          <div>
            <div className="order-process__header-title">
              <div className="header-title"> </div>
              <div className="header-title__name">Наименование</div>
              <div className="header-title__time">Срок изготовления</div>
              <div className="header-title__nominal">Номинал</div>
              <div className="header-title__amount">Кол-во</div>
              <div className="header-title__total">Итого</div>
            </div>
            {items.map((item, index) => (
              <div key={item.id} className="order-process__columns">
                <div className="order-process__item-number-content">
                  <p className="order-process__item-number">
                    {index + 1}.
                  </p>
                </div>
                <div className="order-process__item-name-content">
                  <p className="order-process__item-name">
                    <b className="order-process__item-name_bold">
                      {item.data.name}
                    </b>
                  </p>
                </div>

                <p className="order-process__item-time">
                  1 дней
                </p>
                <p className="order-process__item-nominal">
                  {item.nominal + ' ' + listLang.symbol}
                </p>

                <div className="order-process__item-amount-content">
                  <b className="order-process__item-amount">
                    {item.quantity}
                  </b>
                </div>
                <b className="order-process__item-total">
                  {calculateTotalSum(item) + ' ' + listLang.symbol}
                </b>
              </div>
            ))}
          </div>
          <p className="order-process__total-price">
            Общая сумма: <b className="order-process__total-text">{cart.total + ' ' + listLang.symbol}</b>
          </p>
          <div className="order-process__result-container">
            <b className="order-process__result-title">
              Вы подтверждаете заказ?
            </b>
            <div className="order-process__result-button-container">
              <Button
                customClass="button_no"
                onClick={closePopup}
                title="Нет"
              />
              <Button
                customClass={`button_yes`}
                onClick={handleCreateOrder}
                title="Да"
                disabled={!ndfl.status === 'confirmed' && cart.total < user.points}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="order-process__background">
      <div className="order-process__modal">
        <div className="order-process__close-container">
          <div
            onClick={closePopup}
            className="order-process__close-wrapper"
          >
            <img
              alt="img"
              src={require("../../assets/icons/close.png")}
              className="order-process__close-icon"
            />
          </div>
        </div>
        <div className="order-process__message-container">
          <b className="order-process__message-title">
            Вы оформляете заказ на сумму:{" "}<br/>
            <b className="order-process__message_bold">{cart.total + ' ' + listLang.symbol}</b>
          </b>
          <p className="order-process__message-text">
            Выбранные сертификаты будут отправлены на адрес <br /> электронной
            почты{" "}
            <a
              className="order-process__message-link"
              href="email_fhrfsgs@mail.ru"
            >
              {user.email}
            </a>{" "}
          </p>
          <div className="order-process__header-title">
            <div className="header-title"> </div>
            <div className="header-title__name">Наименование</div>
            <div className="header-title__time">Срок изготовления</div>
            <div className="header-title__nominal">Номинал</div>
            <div className="header-title__amount">Кол-во</div>
            <div className="header-title__total">Итого</div>
          </div>
          <div className={'order-process__columns-container'}>
            {items.map((item, index) => (
              <div key={item.id} className="order-process__columns">
                <div className="order-process__item-number-content">
                  <p className="order-process__item-number">
                    {index + 1}.
                  </p>
                </div>
                <div className="order-process__item-name-content">
                  <p className="order-process__item-name">
                    <b className="order-process__item-name_bold">
                      {item.data.name}
                    </b>
                  </p>
                </div>

                <p className="order-process__item-time">
                  1 дней
                </p>
                <p className="order-process__item-nominal">
                  {item.nominal + ' ' + listLang.symbol}
                </p>

                <div className="order-process__item-amount-content">
                  <b className="order-process__item-amount">
                    {item.quantity}
                  </b>
                </div>
                <b className="order-process__item-total">
                  {calculateTotalSum(item) + ' ' + listLang.symbol}
                </b>
              </div>
            ))}
          </div>

          <p className="order-process__total-price">
            Общая сумма: <b>{cart.total + ' ' + listLang.symbol}</b>
          </p>
          <div className="order-process__result-container">
            <b className="order-process__result-title">
              Вы подтверждаете заказ?
            </b>
            <div className="order-process__result-button-container">
              <Button
                customClass="button_no"
                onClick={closePopup}
                title="Нет"
              />
              <Button
                customClass={`button_yes`}
                onClick={handleCreateOrder}
                title="Да"
                disabled={!ndfl.status === 'confirmed' && cart.total < user.points}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderProcess;
