import React from "react";
import TopMenu from "../components/TopMenu/TopMenu";
import Header from "../components/Header/Header";
import OrderList from "../components/OrderList/OrderList";
import GoUp from "../components/GoUp/GoUp";

const Orders = () => {
  return (
    <div>
      <GoUp />
      <Header />
      <TopMenu />
      <OrderList />
    </div>
  );
};

export default Orders;
