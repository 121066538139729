import "./infoSelfi.css";

const InfoSelfi = () => {
  return (
    <div className="info-selfi__container">
      <div className="info-selfi__content">
        <div>
          <p className="info-selfi__description">
            K Анкете НДФЛ мы просим приложить фото с паспортом. Это нужно для того, чтобы убедиться, что к нам обращается именно TOT человек, который участвует в ТМ-программе.
          </p>
          <p className="info-selfi__description info-selfi__description_bold">Пожалуйста, прочитайте, какой должна быть эта фотография.</p>
          <ul className="info-selfi__list">
            <li>Расположите документ под небольшим углом, — это поможет избежать бликов при съёмке.</li>
            <li>Убедитесь, что на получившейся фотографии ваше лицо полностью находится в кадре и хорошо освещено, а фото и текст паспорта видно чётко.</li>
          </ul>
        </div>
        <div className="info-selfi__container-image">
          <img src={require("../../assets/icons/selfi.png")} alt='image' className="info-selfi__image"/>
        </div>
      </div>
    </div>
  )
};

export default InfoSelfi;