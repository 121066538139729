import React from "react";

import { closeModal, formatNumber } from "../../utils/helpers";

import './AcceptedModal.css'

const AcceptedModal = (props) => {
  const { count, card, setIsInfoToAccepted, handleClickInfoToAccepted, type } = props;

  const handleClickCloseModal = () => {
    setIsInfoToAccepted(false);
    closeModal();
  };

  const acceptedType = (type) => {
    switch(type) {
      case 'sbp': 
        return (
          <>
            номер получателя <strong>{`+7 XXX XXX-${card.slice(-5)}`}</strong> 
          </>
        );
      case 'phone':
        return (
          <>
            на счёт мобильного телефона <strong>{`+7 XXX XXX-${card.slice(-5)}`}</strong> 
          </>
        );
      default :
        return (
          <>
            на карту с номером <strong>{`XXXX XXXX XXXX ${card.slice(-4)}`}</strong>
          </>
        )
    }
  };

  return (
    <div className="accepted-modal">
      <div className="accepted-modal__content">
        <img
          onClick={handleClickCloseModal}
          alt="img"
          src={require("../../assets/icons/close.png")}
          className="accepted-modal__icon"
        />
        <h3 className={'accepted-modal__title'}>
          Вы совершаете вывод <br/>
          <strong className={'accepted-modal__title_bold'}>{formatNumber(count)} баллов</strong>
        </h3>
        <p className={'accepted-modal__rules accepted-modal__input-masked'}>
          {acceptedType(type)}
        </p>
        <div className={'accepted-modal__form-container'}>
          <span className={'accepted-modal__label'}>Вы подтверждаете операцию?</span>
          <div className={'accepted-modal__btn-container'}>
            <button onClick={handleClickCloseModal} className={'accepted-modal__close'}>Нет</button>
            <button onClick={handleClickInfoToAccepted}  className={'accepted-modal__on'}>Да</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AcceptedModal;