import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { stateUpdate } from "../utils";
import ax from "../../utils/ax";
import { getInitialState } from "../../utils/helpers";

const initialState = getInitialState('userState') ||{
  isDemo: false,
  profile_id: null,
  token: null,
  wtt: null,
  points: 0,
  first_name: "",
  last_name: "",
  patronymic: "",
  date_of_birth: "",
  phone: "",
  email: "",
  gender: "",
  lang: 'ru',
};

export const getProfile = createAsyncThunk(
  "profile/get",
  async () => {
    try {
      const {data} = await ax().get('profile/get', {})
      return data;
    } catch (error) {
      return 'error';
    }
  }
)

export const getProfileBalance = createAsyncThunk(
  "profile/get-balance",
  async () => {
    try {
      const {data} = await ax().get('profile/get-balance', {})
      return data;
    } catch (error) {
      return 'error';
    }
  }
)

export const EditProfileSend = createAsyncThunk('profile/edit', 
  async(userData) => {
    try {
      const {data} = await ax().get('profile/edit', {params: userData})
      return data;
    } catch (error) {
      return 'error';
    }
  }
)

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    auth(state, action) {
      stateUpdate(state, action.payload);
    },
    setDemo(state, action) {
      state.isDemo = action.payload.isDemo;
    },
    updateUser(state, action) {
      stateUpdate(state, action.payload);
    },
    setLang(state, action) {
      state.lang = action.payload;
    },
    setListLang(state, action) {
      state.listLang = action.payload;
    },
  },
});

export const { auth, setDemo, updateUser, setLang, setListLang } = userSlice.actions;

export default userSlice.reducer;
