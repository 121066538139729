import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import MaskedInput from "react-text-mask";
import moment from "moment";

import ErrorModal from "../ErrorModal/ErrorModal";
import Button from "../Button/Button";
import AcceptedModal from "../AcceptedModal/AcceptedModal";
import { updateUser, createOrder } from "../../actions/widgetActions";
import { 
  getScrollbarWidth, 
  handleOnKeyDownValidate, 
  handleOnChangeCommissionSum, 
  openModal, 
  validateNumber, 
  validateSum } from "../../utils/helpers";
import useDebounce from "../../hooks/useDebounce";
import { createPayment, postCreatePayment } from "../../redux/reducers/paymentReducer";
import { getTotalSum } from "../../redux/reducers/calculationReducer";

import { ReactComponent as Warning } from '../../assets/iconSvg/warning.svg';

import "./BankCard.css";

const BankCard = () => {
  const [card, setCard] = useState("");
  const [count, setCount] = useState("");
  const [messages, setMessages] = useState([]);
  const [sumWarning, setSumWarning] = useState(false);
  const [isInfoToAccepted, setIsInfoToAccepted] = useState(false);
  const [errors, setErrors] = useState([]);
  const [cardNumberErrors, setCardNumberErrors] = useState([]);
  const [total, setTotal] = useState(0);
  const [commissionUser, setCommissionUser] = useState(0);
  const [taxUser, setTaxUser] = useState(0);

  const orders = useSelector((state) => state.orders);
  const user = useSelector((state) => state.user);
  const ndfl = useSelector((state) => state.ndfl);
  const listLang = useSelector((state) => state.listLang);
  const taxSettings = useSelector((state) => state.settings);

  const dispatch = useDispatch();

  const scrollWidth = getScrollbarWidth();
  const debounceSum = useDebounce(count, 300);

  const { user_markup, max, min } = taxSettings.payment_settings.bank_card || {};
  
  const userCommission = parseFloat(user_markup);
  const ndflTax = parseFloat(taxSettings.payment_settings.tax?.user) || 0;

  const handleClickInfoToAccepted = () => {
    const postData = {
      payment_type : "card",
      sum : Math.round(parseFloat(count) * 100),
      data : {
        card : card.split(' ').join('')
      }
    };

    dispatch(postCreatePayment(postData)).then(res => {
      if (res.payload.success) {
        let date = new moment(new Date()).format("DD.MM.YYYY");
        let order = {
          id: res.payload.data.order_id,
          date,
          price: parseInt(total) || 0,
          type: "card",
          items: [
            {
              name: card,
              id: 0,
              status: res.payload.data.order_status,
              price: parseInt(count) || 0,
              commission: parseFloat(user_markup),
            },
          ],
        };

        dispatch(updateUser({points: user.points - (parseInt(total) || 0)}));
        dispatch(createOrder({ item: order }));
        dispatch(createPayment({item: order}));

        setCard('');
        setCount('');
        setTotal(0);
        setCommissionUser(0);
        setTaxUser(0);
        setMessages([
          {
            title: "Платеж успешно выполнен",
            message: "",
            firstColor: "#000",
            secondColor: "#000",
          },
        ]);
      } else {
        setMessages([
          {
            title: res.payload.response.data.message || "Произошла ошибка",
            message: res.payload.response.data.error || '',
            firstColor: "#000",
            secondColor: "#000",
          },
        ]);
      }
    })

    setIsInfoToAccepted(false);
  };

  const handleClickButtonSend = () => {
    const validationErrors = validateSum(
      count, 
      listLang.maxPaymentBankValue, 
      listLang.minPaymentBankValue, 
      user.points, 
    );

    const validationCardErrors = validateNumber(
      card,
      '',
      'card',
    );

    if (validationErrors.length > 0 || validationCardErrors.length > 0) {
      setErrors(validationErrors);
      setCardNumberErrors(validationCardErrors);
      return;
    }
    
    if (!card || !parseInt(count)) return;
    if (card.replace(/_/g, '').length !== 19) return;
    if ((parseInt(count) || 0) > 14500) return;
    if ((parseInt(count) || 0) > user.points) return;
    
    setCardNumberErrors([]);
    setErrors([]);
    openModal(scrollWidth);
    setIsInfoToAccepted(true);
  };

  const handleOnChangeCount = (event) => {
    const value = event.target.value;
    
    if (value) {
      const validationErrors = validateSum(
        value, 
        parseFloat(+max), 
        parseFloat(+min),
        user.points,
      );

      setCount(value); 

      if (validationErrors.length > 0) {
        setErrors(validationErrors);
        setTotal(0);
        setCommissionUser(0);
        setTaxUser(0);
        return;
      };
    } else {
      setCount('');
      setTotal(0);
      setCommissionUser(0);
      setTaxUser(0);
    }
    
    if (errors.length > 0) setErrors([]);
  };

  const handleOnChangeCardNumber = (event) => {
    const value = event.target.value;
    
    if (value) {
      const validationErrors = validateNumber(
        value, 
        '',
        'card',
      );

      setCard(value);

      if (validationErrors.length > 0) {
        setCardNumberErrors(validationErrors);
        return;
      };
    } else {
      setCard('');
    }

    if (cardNumberErrors.length > 0) setCardNumberErrors([]);
  };

  const handleSumWarning = (propsSum) => {
    const currentValue = propsSum ? propsSum : total;

    if (!currentValue.length) {
      setSumWarning(true);
      return;
    }

    setSumWarning(false);
  };

  useEffect(() => {
    if (debounceSum && debounceSum >= parseFloat(min)) {
      const postData = {
        payment_type: 'card',
        sum: Math.round(parseFloat(debounceSum) * 100)
      };

      dispatch(getTotalSum(postData)).then(res => {
        if (res.payload.success) {
          const userTotalSum = res.payload.data.sum_user / 100 || 0;
          const userTotalCommission = res.payload.data.commission_user / 100 || 0;
          const userTotalTax = res.payload.data.tax_user / 100 || 0;

          setTotal(userTotalSum);
          setCommissionUser(userTotalCommission);
          setTaxUser(userTotalTax);
        } 
      })
    }

  }, [debounceSum, min, dispatch]);

  const renderCardNumber = () => {
    return (
      <div className="bank-card__card-header-content">
        <b className="bank-card__field-header">Номер карты</b>
        <MaskedInput
          value={card}
          type="tel"
          placeholder="0000 0000 0000 0000"
          className={`input input_number bank-card__card-number ${cardNumberErrors.length > 0 ? 'input_warning' : ''}`}
          mask={[
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            " ",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            " ",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            " ",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onChange={(t) => handleOnChangeCardNumber(t)}
        />
        {cardNumberErrors.length > 0 ? ( 
          cardNumberErrors.map((error, index) => (
            <b key={index} className="bank-card__warning">{error}</b>
          ))
        ) : <br />}
      </div>
    );
  };

  const renderCount = () => {
    return (
      <div className={'bank-card__pay-form_count'}>
        <b className="bank-card__field-header">Сумма перевода</b>
        <input
          type="number"
          value={count}
          placeholder={listLang.symbol}
          className={`
            input
            bank-card__input_count
            ${errors.length > 0
              ? 'input_warning'
              : ''
            }
          `}
          onChange={(t) => handleOnChangeCount(t)}
          onKeyDown={(event) => handleOnKeyDownValidate(event)}
          onBlur={() => setCount(count) }
        />
        {!user.isDemo && count > 0 ? (
          <span
            className={`
              bank-card__input_count-tip 
              ${sumWarning || parseFloat(total) - listLang.maxPaymentBankValue > 0 || parseInt(total) > user.points
                ? "bank-card__input_count-tip_warning"
                : ""
              }
            `}>
          </span>
        ) : null}
        {errors.length > 0 ? (
          errors.map((error, index) => (
            <b key={index} className="bank-card__warning">{error}</b>
          ))
        ) : (
          <br />
        )}
      </div>
    );
  };

  const renderCommission = () => {
    return (
      <div className={'bank-card__pay-form_count'}>
        <div className="bank-card__header-container">
          <b className="bank-card__field-header bank-card__input-title">Комиссия</b>
          <Warning />
        </div>
        <input
          type="number"
          value={commissionUser}
          disabled
          placeholder={listLang.symbol}
          className={`
            input
            bank-card__input_count
            bank-card__commission
            ${
            sumWarning ||
            count.length > 0 && parseFloat(total) - listLang.maxPaymentBankValue > 0 || parseInt(total) > user.points
              ? 'input_warning'
              : ''
            }
          `}
          onChange={(t) => handleOnChangeCommissionSum(t, setCount, handleSumWarning)}
          onKeyPress={(event) => {
            if (!(/[0-9]/.test(event.key) || event.key === ".")) {
              event.preventDefault();
            }
          }}
          onBlur={() => setCount(count) }
        />
      </div>
    );
  };

  const renderCommissionTax = () => {
    return (
      <div className={'bank-card__pay-form_count'}>
        <div className="bank-card__header-container">
          <b className="bank-card__field-header bank-card__input-title">{listLang.taxSystem}</b>
          <Warning />
        </div>
        <input
          type="number"
          value={taxUser}
          disabled
          placeholder={listLang.symbol}
          className={`
            input
            bank-card__input_count
            bank-card__commission
            ${
            sumWarning ||
            count.length > 0 && parseFloat(total) - listLang.maxPaymentBankValue > 0 || parseInt(total) > user.points
              ? 'input_warning'
              : ''
            }
          `}
          onChange={(t) => handleOnChangeCommissionSum(t, setCount, handleSumWarning)}
          onKeyPress={(event) => {
            if (!(/[0-9]/.test(event.key) || event.key === ".")) {
              event.preventDefault();
            }
          }}
          onBlur={() => setCount(count) }
        />
      </div>
    );
  };

  const renderWarning = () => {
    return (
      <div className="bank-card__field-error">
        <img
          alt="img"
          src={require("../../assets/icons/RubleWarning.png")}
          className="bank-card__field-error-icon"
        />
        Максимальная сумма перевода составляет {listLang.maxPaymentBankText}{listLang.symbol} за один платеж.
      </div>
    );
  };

  const renderSend = () => {
    return (
      <div className="bank-card__button-container">
        <Button
          customClass="button_send bank-card__button"
          onClick={handleClickButtonSend}
          title="Отправить"
          disabled={ndfl.status !== "confirmed"}
        />
        <br/>
      </div>
    );
  };

  return (
    <div>
      {renderWarning()}
      <div className={`bank-card__container`}>
        <ErrorModal messages={messages} setMessages={setMessages} />
        {renderCardNumber()}
        <br />
        <div className={'bank-card__count-container'}>
          {renderCount()}
          {userCommission ? renderCommission() : null}
          {ndflTax ? renderCommissionTax() : null}
        </div>
        <br />
        <div className={'bank-card__total-container'}>
          <p className={'bank-card__total'}>
            Итого: <span className={'bank-card__sum'}>{total}</span> {listLang.symbol}
          </p>
          <span className={'bank-card__sum-info'}>Сумма будет списана с баланса баллов</span>
        </div>
        {renderSend()}
        {isInfoToAccepted ? (
          <AcceptedModal
            card={card}
            count={total}
            type={'card'}
            setIsInfoToAccepted={setIsInfoToAccepted}
            handleClickInfoToAccepted={handleClickInfoToAccepted}
          />
        ) : null}
      </div>
    </div>
  );
};

export default BankCard;
