import { useSelector, useDispatch } from "react-redux";
import React, {useEffect, useState} from "react";
import { useMediaQuery } from "react-responsive";
import MaskedInput from "react-text-mask";

import ErrorModal from "../ErrorModal/ErrorModal";
import { ndflUpdate } from "../../actions/widgetActions";
import Button from "../Button/Button";
import Select from "../Select/Select";
import LeavingModal from "../LeavingModal/LeavingModal";
import NDFLStatus from "../NDFLStatus/NDFLStatus";
import { getPhotoUrl, getScrollbarWidth, openModal } from "../../utils/helpers";

import {ReactComponent as Trash} from "../../assets/iconSvg/trash-white.svg";

import "./ndfl_form_by.css";

const NDFLForm = () => {
  const dispatch = useDispatch();

  const isMobile = useMediaQuery({ maxWidth: 768 });
  const ndfl = useSelector((state) => state.ndfl);
  const [first_name, setfirst_name] = useState(ndfl.first_name || "");
  const [patronymic, setMiddleName] = useState(ndfl.patronymic || "");
  const [last_name, setLastName] = useState(ndfl.last_name || "");
  const [inn, setInn] = useState(ndfl.inn || "");
  const [files, setFiles] = useState({});
  const [messages, setMessages] = useState([]);
  const [passportCode, setPassportCode] = useState(ndfl.passportCode || "");
  const [passportNumber, setPassportNumber] = useState(
    ndfl.passportNumber || ""
  );

  const [lastNameError, setLastNameError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [patronymicError, setPatronymicError] = useState(false);
  const [passportCodeError, setPassportCodeError] = useState(false);
  const [passportNumberError, setPassportNumberError] = useState(false);
  const [innError, setInnError] = useState(false);
  const [visibleDropDownIdentityDocument, setVisibleDropDownIdentityDocument] = useState(false);
  const [identityDocument, setIdentityDocument] = useState();
  const [ndflStatus, setNdflStatus] = useState(ndfl.status_type);
  const scrollWidth = getScrollbarWidth();


  const identityDocumentList = [
    {id: 0, title: 'Паспорт гражданина Республики Беларусь'},
    {id: 1, title: 'Идентификационная карта гражданина Республики Беларусь'},
  ];

  const wherePromptVisible =
    ndfl.first_name !== first_name ||
    ndfl.patronymic !== patronymic ||
    ndfl.last_name !== last_name ||
    ndfl.inn !== inn ||
    ndfl.identity_document !== identityDocument;


  const handleFilesChange = (e, fileKey) => {
    if (e.target.files) {
      const preImg = getPhotoUrl(e.target.files[0]);
      preImg.onload = () => setFiles({...files, [fileKey]: preImg.result});
    }
  };

  const handleClickSend = () => {
    if (
      lastNameError ||
      firstNameError ||
      patronymicError ||
      innError ||
      !identityDocument ||
      passportCodeError ||
      passportNumberError
    ) return;

    dispatch(
      ndflUpdate({
        first_name,
        last_name,
        patronymic,
        inn,
        passportCode,
        passportNumber,
        identity_document: identityDocument,
      })
    );

    setMessages([
      {
        title: "Изменения прошли успешно!",
        message: "",
        firstColor: "#000",
        secondColor: "#000",
      },
    ]);

    setNdflStatus('pending');
    dispatch(ndflUpdate({status: 'pending'}));

    openModal(scrollWidth);
  };

  const checkNameError = (value, setError) => {
    if (value.length < 2 || value.length > 30) {
      setError(true);
      return;
    }

    setError(false);
  };

  const checkInnError = (value) => {
    const currentValue = value || inn;

    if (currentValue.length < 10 || currentValue.length > 12) {
      setInnError(true);
      return;
    }

    setInnError(false);
  };

  const checkPassportError = (value, setError) => {
    if (!value || value.includes('_')) {
      setError(true);
      return;
    }

    setError(false);
  };

  const renderErrorName = (value) => (
    value.length < 2
      ? <b className="ndfl-form-by__warning">Мало символов</b>
      : value.length > 30
      ? <b className="ndfl-form-by__warning">Много символов</b>
      : <br/>
  );

  const renderErrorInn = (value) => (
    value.length < 10
      ? <b className="ndfl-form-by__warning">Мало символов</b>
      : value.length > 12
        ? <b className="ndfl-form-by__warning">Много символов</b>
        : <br/>
  );

  useEffect(() => {
    return () => {
      window.close();
    }
  }, [window]);

  const renderInputField = (title, placeholder, value, onChange, error, renderError, typeKeyboard = 'text') => {
    return (
      <div className="ndfl-form-by__field_wrapper">
        <b className="ndfl-form-by__field-header">{title}</b>
        <input
          value={value}
          type={typeKeyboard}
          readOnly={ndflStatus}
          placeholder={placeholder}
          className={`input input__ndfl ${error ? 'input_warning' : ''} ${ndflStatus ? 'ndfl-form-by__input_readonly' : ''}`}
          onChange={onChange}
        />
        {renderError && renderError(value)}
      </div>
    );
  };

  const renderDescription = () => {
    return (
      <div className="ndfl-form-by__description">
        Нажимая кнопку «Отправить», я подтверждаю,
        что я ознакомлен и согласен с условиями
        <a href="/rules.pdf" target={'_blank'} className="input__ndfl-link">
          политики обработки персональных данных
        </a>{" "}
        .
      </div>
    );
  };

  const renderPhotoDescription = () => {
    return (
      <div className="ndfl-form-by__photo_description">
        Допустимый формат фото паспорта, для прикрепления:{" "}
        <span style={{ color: "#000" }}>
          jpg, png, pdf
          <br/>
        </span>{" "}
        максимальный размер не более{" "}
        <span style={{ color: "#000" }}>10 Мб.</span>
      </div>
    );
  };

  const renderErrorPassport = (value) => (
    !value || value.includes('_')
      ? <b className="ndfl-form-by__warning">Поле не заполнено</b>
      : <br/>
  );

  const renderPassport = () => {
    return (
      <div className="ndfl-form-by__passport">
        <div className="ndfl-form-by__seria">
          <b className="ndfl-form-by__passport_title">Серия паспорта</b>
          <MaskedInput
            type="text"
            value={passportCode}
            placeholder={"XXXX"}
            readOnly={ndflStatus}
            className={`input ndfl-form-by__seria_value ${passportCodeError ? 'input_warning' : ''} ${ndflStatus ? 'ndfl-form-by__input_readonly' : ''}`}
            mask={[/\w/, /\w/, /\w/, /\w/]}
            onChange={(t) => {
              setPassportCode(t.target.value);
              checkPassportError(t.target.value, setPassportCodeError);
            }}
          />
          {renderErrorPassport(passportCode)}
        </div>
        <div className="ndfl-form-by__number">
          <b className="ndfl-form-by__passport_title">Номер паспорта</b>
          <MaskedInput
            type="text"
            value={passportNumber}
            placeholder={"XXXXXX"}
            readOnly={ndflStatus}
            className={`input ndfl-form-by__number_value ${passportNumberError ? 'input_warning' : ''} ${ndflStatus ? 'ndfl-form-by__input_readonly' : ''}`}
            mask={[/\w/, /\w/, /\w/, /\w/, /\w/, /\w/]}
            onChange={(t) => {
              setPassportNumber(t.target.value);
              checkPassportError(t.target.value, setPassportNumberError);
            }}
          />
          {renderErrorPassport(passportNumber)}
        </div>
      </div>
    );
  };

  const renderPhotoLoad = (title, id) => {
    const value = files[id];
    if (value) {
      return (
        <div className={`ndfl-form-by__photo-load ${ndflStatus ? "reset-cursor" : ''}`}>
          <div className={`ndfl-form-by__photo_loaded ${ndflStatus ? "ndfl-form-by__photo-loaded" : ''}`}>
            <div className={`ndfl-form-by__photo-loaded_wrapper ${ndflStatus ? 'status-true' : ''}`}>
              <div className="ndfl-form-by__background"></div>
              <img className="ndfl-form-by__photo-loaded_image" src={value}/>
              {!ndflStatus ? (
                <Trash
                  className="trash ndfl-form-by__trash"
                  onClick={() => setFiles({...files, [id]: null})}
                />
              ) : null}
            </div>
          </div>
        </div>
      );
    }

    return (
      <label htmlFor={id} className={`ndfl-form-by__photo-load ${ndflStatus ? 'reset-cursor' : ''}`}>
        <input
          type={ndflStatus ? 'text' : 'file'}
          readOnly={ndflStatus}
          hidden
          id={id}
          accept="image/png, image/gif, image/jpeg"
          onChange={(e) => handleFilesChange(e, id)}
        />
        <div className={`ndfl-form-by__photo_load ${ndflStatus ? 'ndfl-status__photo_load-disable' : ''}`}>
          <img
            alt="img"
            src={require("../../assets/icons/file.png")}
            className="ndfl-form-by__load_icon"
          />
          {title}
        </div>
      </label>
    );
  };

  const renderPhoto = () => {
    const loadedFiles = files.file1 && files.file2
    return (
      <div className="ndfl-form-by__field-photo_wrapper">
        <b className="ndfl-form-by__field-header">Фото документа</b>
        <div className={`ndfl-form-by__photo-container ${loadedFiles ? 'ndfl-form-by__photo-container_row' : ''}`}>
          {renderPhotoLoad(
            "Разворот паспорта",
            "file1"
          )}
          {renderPhotoLoad(
            "Разворот с регистрацией (прописка)",
            "file2"
          )}
        </div>
      </div>
    );
  };

  const renderSend = () => {
    return (
      <Button
        customClass="button_save"
        onClick={handleClickSend}
        title="Отправить"
      />
    );
  };

  if (isMobile) {
    return (
      <>
        <LeavingModal wherePromptVisible={wherePromptVisible}/>
        {ndflStatus ? <NDFLStatus status={ndflStatus} setNdflStatus={setNdflStatus}/> : null}
        <div className="ndfl-form-by__container">
          {renderInputField(
            "Фамилия",
            "",
            last_name,
            (t) => {
              setLastName(t.target.value);
              checkNameError(t.target.value, setLastNameError);
            },
            lastNameError,
            renderErrorName
          )}
          {renderInputField(
            "Имя",
            "",
            first_name,
            (t) => {
              setfirst_name(t.target.value);
              checkNameError(t.target.value, setFirstNameError);
            },
            firstNameError,
            renderErrorName
          )}
          {renderInputField(
            "Отчество",
            "",
            patronymic,
            (t) => {
              setMiddleName(t.target.value);
              checkNameError(t.target.value, setPatronymicError);
            },
            patronymicError,
            renderErrorName
          )}
          {renderInputField(
            'Личный номер',
            "NNNNXXXXXXCC",
            inn,
            (t) => {
              setInn(t.target.value);
              checkInnError(t.target.value);
            },
            innError,
            renderErrorInn,
            'number'
          )}
          <Select
            title={'Документ, удостоверяющий личность'}
            placeholder={'Выбрать документ'}
            visible={visibleDropDownIdentityDocument}
            setVisible={setVisibleDropDownIdentityDocument}
            list={identityDocumentList}
            value={identityDocument}
            setValue={setIdentityDocument}
            ndflStatus={ndflStatus}
          />
          {identityDocument && identityDocument.id === 0 ? renderPassport() : null}
          {renderPhoto()}
          {renderPhotoDescription()}
          {!ndflStatus ? renderDescription() : null}
          {!ndflStatus ? renderSend() : null}
          <ErrorModal messages={messages} setMessages={setMessages} />
        </div>
      </>
    );
  }

  return (
    <div>
      <p className={'ndfl-form-by__warning_text'}>Все поля – обязательны для заполнения!</p>
      <LeavingModal wherePromptVisible={wherePromptVisible}/>
      {ndflStatus ? <NDFLStatus status={ndflStatus} setNdflStatus={setNdflStatus}/> : null}
      <div className="ndfl-form-by__container">
        <div className={'ndfl-form-by__fio-container'}>
          {renderInputField(
            "Фамилия",
            "",
            last_name,
            (t) => {
              setLastName(t.target.value);
              checkNameError(t.target.value, setLastNameError);
            },
            lastNameError,
            renderErrorName
          )}
          {renderInputField(
            "Имя",
            "",
            first_name,
            (t) => {
              setfirst_name(t.target.value);
              checkNameError(t.target.value, setFirstNameError);
            },
            firstNameError,
            renderErrorName
          )}
          {renderInputField(
            "Отчество",
            "",
            patronymic,
            (t) => {
              setMiddleName(t.target.value);
              checkNameError(t.target.value, setPatronymicError);
            },
            patronymicError,
            renderErrorName
          )}
        </div>
        <div>
          {renderInputField(
            "Личный номер",
            "NNNNXXXXXXCC",
            inn,
            (t) => {
              setInn(t.target.value);
              checkInnError(t.target.value);
            },
            innError,
            renderErrorInn,
            'number'
          )}
          <Select
            className={'ndfl-form-by__select'}
            title={'Документ, удостоверяющий личность'}
            placeholder={'Выбрать документ'}
            visible={visibleDropDownIdentityDocument}
            setVisible={setVisibleDropDownIdentityDocument}
            list={identityDocumentList}
            value={identityDocument}
            setValue={setIdentityDocument}
            ndflStatus={ndflStatus}
          />
          {identityDocument && identityDocument.id === 0 ? renderPassport() : null}
          {renderPhoto()}
          {renderPhotoDescription()}
          {!ndflStatus ? renderDescription() : null}
          {!ndflStatus ? renderSend() : null}
        </div>

        <ErrorModal messages={messages} setMessages={setMessages} />
      </div>
    </div>
  );
};

export default NDFLForm;
