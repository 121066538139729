import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ax from "../../utils/ax";

const initialState = {
  items: [],
};

export const getAllCards = createAsyncThunk(
  "cards/get-all",
  async () => {
    try {
      const {data} = await ax().get('cards/get-all', {})
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const getCard = createAsyncThunk(
  "cards/get",
  async (guid) => {
    try {
      const {data} = await ax().get('cards/get', {params: guid})
      return data;
    } catch (error) {
      return error;
    }
  }
);

const prizesSlice = createSlice({
  name: "prizes",
  initialState,
  reducers: {
    setPrizes(state, action) {
      const { prize_list } = action.payload;
      state.items = prize_list;
    },
  },
});

export const { setPrizes } = prizesSlice.actions;

export default prizesSlice.reducer;
