import React, {useEffect, useState} from "react";
import ReactRouterPrompt from "react-router-prompt";
import {useDispatch, useSelector} from "react-redux";
import {setVisibleWherePromptVisible} from "../../redux/reducers/ndflReducer";
import {setLang} from "../../redux/reducers/listlangReducer";

import { closeModal, getScrollbarWidth, openModal } from "../../utils/helpers";

import "./LeavingModal.css";

const LeavingModal = ({wherePromptVisible}) => {
  const dispatch = useDispatch();
  const scrollWidth = getScrollbarWidth();
  const [isClose, setIsClose] = useState(false);

  const idLandForLeavingModal = useSelector((state) => state.ndfl.idLandForLeavingModal);

  useEffect(() => {
    dispatch(setVisibleWherePromptVisible(wherePromptVisible));
  }, [wherePromptVisible, dispatch]);

  return (
    <ReactRouterPrompt when={wherePromptVisible}>
      {({ isActive, onConfirm, onCancel }) => {
        if (isActive && !isClose) {
          openModal(scrollWidth);
        }
        
        const handleClickConfirm = () => {
          if (idLandForLeavingModal === 'home') {
            dispatch(setLang('ru'));
          }

          if (idLandForLeavingModal === 'kz') {
            dispatch(setLang('kz'));
          }

          if (idLandForLeavingModal === 'by') {
            dispatch(setLang('by'));
          }

          onConfirm();
          closeModal();
          setIsClose(true);

          dispatch(setVisibleWherePromptVisible(false));
        };

        const handleClickCancel = () => {
          onCancel();
          closeModal();
        };

        return (
          <div className="background" onClick={handleClickConfirm}>
            <div className="leaving-modal__popup-container" onClick={(e) => e.stopPropagation()}>
              <img
                onClick={handleClickConfirm}
                alt="img"
                src={require("../../assets/icons/close.png")}
                className="leaving-modal__icon"
              />
              <div className="wrapper">
                <div className={'leaving-modal__title'}>Если вы покинете данную страницу, ваши данные не будут сохранены</div>
                <div className={'leaving-modal__form-container'}>
                  <span className={'leaving-modal__label'}>Вы подтверждаете операцию?</span>
                  <div className="leaving-modal__btn-container">
                    <button onClick={handleClickCancel} className={'leaving-modal__close'}>Нет</button>
                    <button onClick={handleClickConfirm} className={'leaving-modal__on'}>Да</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </ReactRouterPrompt>
  );
}

export default LeavingModal;