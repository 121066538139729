import React from "react";
import TopMenu from "../components/TopMenu/TopMenu";
import Header from "../components/Header/Header";
import Prize from "../components/Prize/Prize";
import { useLocation } from "react-router-dom";
import GoUp from "../components/GoUp/GoUp";

const CertificateDetailed = () => {
  const location = useLocation();
  let state = location.state;
  return (
    <div>
      <Header />
      <TopMenu />
      <Prize item={state.data} />
      <GoUp />
    </div>
  );
};

export default CertificateDetailed;
