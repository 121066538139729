import React, { memo, useCallback, useEffect } from "react";

const PrizeItem = memo(({items, item, isPosted, setItems, user, listLang}) => {
  const { price, user_markup, user_discount, amount } = item;

  const handleClickItem = useCallback((epsItem, action) => {
    let itemsCopy = [...items];
    const index = itemsCopy.findIndex(item => item.id === epsItem.id);

    if (action === 'plus') {
      itemsCopy[index].amount = itemsCopy[index].amount + 1;
    } else {
      if (epsItem.amount > 0) {
        itemsCopy[index].amount = itemsCopy[index].amount - 1;
      } else if (epsItem.isNew) {
        itemsCopy.splice(index, 1);
      }
    }

    setItems(itemsCopy);
  }, [items, setItems]);

  useEffect(() => {
    if (isPosted) {
      setItems(items);
    }
  }, [items, isPosted, setItems]);

  return (
    <div className="prize__item-container">
      <div
        className={`
          prize__item-text 
          prize__item-price-container 
          ${user.isDemo ? 'prize__item-price_is-commission' : ''}
        `}>
        <b>{price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</b> {listLang.symbol}
      </div>
      {user_markup ? (
        <div className="prize__item-text prize__item-commission-container">
          <b className="prize__item-commission">{user_markup} %</b>
        </div>
      ) : null}

      <div className="prize__item-text prize__item-amount-container">
        <img
          alt="img"
          src={require("../../../assets/icons/minus.png")}
          className="prize__item-amount-img"
          onClick={() => handleClickItem(item, 'minus')}
        />
        <b className="prize__item-amount">
          {" "}
          {amount}
        </b>
        <img
          alt="img"
          src={require("../../../assets/icons/blue_plus.png")}
          className="prize__item-amount-img"
          onClick={() => handleClickItem(item, 'plus')}
        />
      </div>
      <div className="prize__item-text prize__item-total-container">
        <b>
          {price * amount
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
        </b>{" "}
        {listLang.symbol}
      </div>
    </div>
  );
});

PrizeItem.displayName = 'PrizeItem';

export default PrizeItem;