import React from "react";

const TypeContainer = ({ status, type, name, orderTypeLabels }) => (
  <div className={`order-list__type-container ${status === "cancel" ? 'order-list__type-container_rejected' : ''}`}>
    <span className="order-list__type-title">
      {orderTypeLabels[type]}
      {name && <span className="order-list__prize-name"> {name}</span>}
    </span>
  </div>
);

export default TypeContainer;