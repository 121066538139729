import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import App from "./App";
import store from "./store";

import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
    <style type="text/css">{`
          @font-face {
            font-family: 'InterBold';
            src: url(${require("./assets/fonts/InterBold.otf")}) format('truetype');
          }
          @font-face {
                      font-family: 'Inter';
                      src: url(${require("./assets/fonts/Inter.otf")}) format('truetype');
                    }
        `}</style>
  </Provider>
);
