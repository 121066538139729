import { useSelector, useDispatch } from "react-redux";
import React, { useState } from "react";
import MaskedInput from "react-text-mask";
import moment from "moment";

import ErrorModal from "../ErrorModal/ErrorModal";
import { updateUser, createOrder } from "../../actions/widgetActions";
import Button from "../Button/Button";
import AcceptedModal from "../AcceptedModal/AcceptedModal"
import { 
  getScrollbarWidth, 
  handleOnKeyDownValidate, 
  handleOnChangeCommissionSum, 
  handleSumCommission, 
  openModal, 
  validateSum } from "../../utils/helpers";
import Select from "../Select/Select";

import { ReactComponent as Warning } from '../../assets/iconSvg/warning.svg';

import "./SBPForm.css";

const SBPForm = () => {
  const [card, setCard] = useState("");
  const [count, setCount] = useState("");
  const [messages, setMessages] = useState([]);
  const [cardWarning, setCardWarning] = useState(false);
  const [sumWarning, setSumWarning] = useState(false);
  const [isInfoToAccepted, setIsInfoToAccepted] = useState(false);
  const [selectedBank, setSelectedBank] = useState('');
  const [visibleDropDownBank, setVisibleDropDownBank] = useState(false);
  const [isBank, setIsBank] = useState(false);
  const [errors, setErrors] = useState([]);
  const [selectError, setSelectError] = useState('');

  const user = useSelector((state) => state.user);
  const listLang = useSelector((state) => state.listLang);
  const orders = useSelector((state) => state.orders);
  const dispatch = useDispatch();

  const scrollWidth = getScrollbarWidth();
  const total = !user.isDemo ? count : handleSumCommission(count, 'phone');

  const bankList = [
    {id: 0, title: 'Сбербанк'},
    {id: 1, title: 'ВТБ'},
    {id: 2, title: 'Альфа-Банк'},
    {id: 3, title: '«Открытие»'},
    {id: 4, title: 'Газпромбанк'},
    {id: 5, title: 'Райффайзен Банк'},
    {id: 6, title: 'Росбанк'},
    {id: 7, title: 'ПСБ'},
    {id: 8, title: 'Совкомбанк'},
    {id: 9, title: 'ЮниКредит Банк'},
    {id: 10, title: 'Россельхозбанк'},
    {id: 11, title: '«Тинькофф»'},
    {id: 12, title: 'Московский кредитный банк'},
  ];

  const handleClickInfoToAccepted = () => {
    let date = new moment(new Date()).format("DD.MM.YYYY");
                
    dispatch(updateUser({points: user.points - (parseInt(total) || 0)}));

    let order = {
      id: orders.items.length + 1,
      date,
      price: parseInt(total) || 0,
      type: "sbp",
      items: [
        {
          name: card,
          id: 0,
          status: "pending",
          price: parseInt(count) || 0,
          commission: 10,
        },
      ],
    };
    dispatch(createOrder({ item: order }));

    setCard('');
    setCount('');
    setSelectedBank('');
    setMessages([
      {
        title: "Платеж успешно выполнен",
        message: "",
        firstColor: "#000",
        secondColor: "#000",
      },
    ]);
    setIsInfoToAccepted(false);
  };

  const handleClickButtonSend = () => {
    const validationErrors = validateSum(
      count, 
      listLang.maxPaymentBankValue, 
      listLang.minPaymentBankValue, 
      user.points, 
    );

    handleCardWarning();

    if (!selectedBank) {
      setSelectError('Поле обязательно для заполнения')
      return;
    };
    
    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      return;
    };
    if (!card || !parseInt(count)) return;
    if (card[card.length - 1] === "_") return;
    if ((parseInt(count) || 0) > user.points) return;
    if ((parseInt(count) || 0) > 14500) return;
    
    setSelectError('');
    setErrors([]);
    openModal(scrollWidth);
    setIsInfoToAccepted(true);
  };

  const handleCardWarning = (propsCard) => {
    const currentValue = propsCard ? propsCard : card;

    if (!currentValue || currentValue.includes('_')) {
      setCardWarning(true);
      return;
    }

    setCardWarning(false);
  };

  const handleSumWarning = (propsSum) => {
    const currentValue = propsSum ? propsSum : total;

    if (!currentValue.length) {
      setSumWarning(true);
      return;
    }

    setSumWarning(false);
  };

  const renderCardNumber = () => {
    return (
      <div className="sbp-form__phone-header-content">
        <b className="sbp-form__field-header">Номер телефона</b>
        <MaskedInput
          value={card}
          type="tel"
          placeholder="+7 XXX XXX-XX-XX"
          className={`input input_number sbp-form__card-number ${cardWarning ? 'input_warning' : ''}`}
          mask={[
            "+",
            "7",
            " ",
            /\d/,
            /\d/,
            /\d/,
            " ",
            /\d/,
            /\d/,
            /\d/,
            "-",
            /\d/,
            /\d/,
            "-",
            /\d/,
            /\d/,
          ]}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onChange={(t) => {
            setCard(t.target.value);
            if (cardWarning) setCardWarning(false);
          }}
        />
        {
          cardWarning
            ? <b className="sbp-form__warning">Неверный номер телефона</b>
            : <br />
        }
      </div>
    );
  };

  const renderCount = () => {
    return (
      <div className={'sbp-form__pay-form_count'}>
        <b className="sbp-form__field-header">Сумма перевода</b>
        <input
          value={count}
          type="number"
          placeholder={listLang.symbol}
          className={`
            input
            sbp-form__input_count
            ${errors.length > 0
              ? 'input_warning'
              : ''
            }
          `}
          onChange={(t) => {
            setCount(t.target.value); 
            if (errors.length > 0) setErrors([]);
          }}
          onKeyDown={(event) => handleOnKeyDownValidate(event)}
          onBlur={() => setCount(count) }
        />
        {!user.isDemo && count > 0 ? (
          <span
            className={`
              sbp-form__count-tip 
              ${sumWarning || parseFloat(count) - listLang.maxPaymentSBPValue > 0 || parseInt(total) > user.points
                ? "sbp-form__count-tip_warning"
                : ""
              }
            `}>
          </span>
        ) : null}
        {errors.length > 0 ? (
          errors.map((error, index) => (
            <b key={index} className="sbp-form__warning">{error}</b>
          ))
        ) : (
          <br />
        )}
      </div>
    );
  };

  const renderSend = () => {
    return (
      <div className="sbp-form__button-container">
        <Button
          customClass="button_send sbp-form__button"
          onClick={handleClickButtonSend}
          title="Отправить"
        />
        <br/>
      </div>
    );
  };

  const renderCommission = () => {
    return (
      <div className={'sbp-form__pay-form_count'}>
        <div className="sbp-form__header-container">
          <b className="sbp-form__field-header sbp-form__input-title">Комиссия</b>
          <Warning />
        </div>
        <input
          type="number"
          value={Math.ceil((count / 100) * 10)}
          disabled
          placeholder={listLang.symbol}
          className={`
            input
            sbp-form__input_count
            sbp-form__commission
            ${
            sumWarning ||
            count.length > 0 && parseFloat(total) - listLang.maxPaymentBankValue > 0 || parseInt(total) > user.points
              ? 'input_warning'
              : ''
            }
          `}
          onChange={(t) => handleOnChangeCommissionSum(t, setCount, handleSumWarning)}
          onKeyPress={(event) => {
            if (!(/[0-9]/.test(event.key) || event.key === ".")) {
              event.preventDefault();
            }
          }}
          onBlur={() => setCount(count) }
        />
        <br/>
      </div>
    );
  };

  const renderCommissionTax = () => {
    return (
      <div className={'sbp-form__pay-form_count'}>
        <div className="sbp-form__header-container">
          <b className="sbp-form__field-header sbp-form__input-title">{listLang.taxSystem}</b>
          <Warning />
        </div>
        <input
          type="number"
          value={Math.ceil((count / 0.87) - count)}
          disabled
          placeholder={listLang.symbol}
          className={`
            input
            sbp-form__input_count
            sbp-form__commission
            ${
            sumWarning ||
            count.length > 0 && parseFloat(total) - listLang.maxPaymentBankValue > 0 || parseInt(total) > user.points
              ? 'input_warning'
              : ''
            }
          `}
          onChange={(t) => handleOnChangeCommissionSum(t, setCount, handleSumWarning)}
          onKeyPress={(event) => {
            if (!(/[0-9]/.test(event.key) || event.key === ".")) {
              event.preventDefault();
            }
          }}
          onBlur={() => setCount(count) }
        />
      </div>
    );
  };

  const renderWarning = () => {
    return (
      <div className="sbp-form__field-error">
        <img
          alt="img"
          src={require("../../assets/icons/RubleWarning.png")}
          className="sbp-form__field-error-icon"
        />
        Максимальная сумма перевода составляет {listLang.maxPaymentSBPText}{listLang.symbol} за один платеж.
      </div>
    );
  };

  const renderSBPInfo = () => {
    return (
      <div className={`sbp-form__wrapper-aside`}>
        <div className="sbp-form__aside-content">
          <img src={require("../../assets/icons/sbp.jpg")} alt='SBP' className="sbp-form__aside-image"/>
          <h2 className="sbp-form__aside-title">
            Переводы в другие банки по номеру телефона!
            <br/>
            <span className="sbp-form__aside-text">Неважно какой банк, главное, чтобы он был подключён к СБП.</span>
          </h2>
          <ul className="sbp-form__aside-list">
            <li className="sbp-form__aside-item">Ввести номер телефона‚ на который будет произведена оплата (без 8 или +7)</li>
            <li className="sbp-form__aside-item">Ввести сумму перевода.</li>
            <li className="sbp-form__aside-item">Выбрать банк получателя.</li>
            <li className="sbp-form__aside-item">Зачисление денежных средств происходит единоразово, полностью в течение 24-х часов.</li></ul>
        </div>
      </div>
    )
  }

  return (
    <div className="sbp-form__container">
      <div className="sbp-form__content-container">
        {renderWarning()}
        <ErrorModal messages={messages} setMessages={setMessages} />
        {renderCardNumber()}
        <br />
        <div className={'sbp-form__count-container'}>
          {renderCount()}
          {
            user.isDemo ? (
              <>
                {renderCommission()}
                {renderCommissionTax()}
              </>
            ) : null
          }
        </div>
        <br />
        <Select
          title={'Выбрать банк получателя'}
          placeholder={'Выбрать банк'}
          visible={visibleDropDownBank}
          setVisible={setVisibleDropDownBank}
          list={bankList}
          value={selectedBank}
          errorMessage={selectError}
          setSelectError={setSelectError}
          setValue={setSelectedBank}
          setHover={setIsBank}
          hoverEffectValue={isBank}
          type={'sbp'}
        />
        <div className={'sbp-form__total-container'}>
          <p className={'sbp-form__total'}>
            Итого: <span className={'sbp-form__sum'}>{total}</span> {listLang.symbol}
          </p>
          <span className={'sbp-form__sum-info'}>Сумма будет списана с баланса баллов</span>
        </div>
        {renderSend()}
        {isInfoToAccepted ? (
          <AcceptedModal
            card={card}
            count={total}
            type={'sbp'}
            setIsInfoToAccepted={setIsInfoToAccepted}
            handleClickInfoToAccepted={handleClickInfoToAccepted}
          />
        ) : null}
      </div>
      {renderSBPInfo()}
    </div>
  );
};

export default SBPForm;
