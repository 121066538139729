import { useSelector, useDispatch } from "react-redux";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import Header from "../../components/Header/Header";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import OrderProcess from "../../components/OrderProcess/OrderProcess";
import OrderComplete from "../../components/OrderComplete/OrderComplete";
import GoBack from "../../components/GoBack/GoBack";
import { addToCart, getBasket, postBasket } from "../../redux/reducers/cartReducer";
import MaxOrder from "./components/MaxOrder";
import ClearCart from "./components/ClearCart";
import List from "./components/List";
import Order from "./components/Order";
import useDebounce from "../../hooks/useDebounce";

import "./cart_screen.css";

const Cart = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const ndfl = useSelector((state) => state.ndfl);
  const listLang = useSelector((state) => state.listLang);
  const cart = useSelector((state) => state.cart);
  const taxSettings = useSelector((state) => state.settings.payment_settings.tax);
  
  const [messages, setMessages] = useState([]);
  const [showOrderComplete, setShowOrderComplete] = useState(false);
  const [showOrderProcess, setShowOrderProcess] = useState(false);
  const [getError, setGetError] = useState([]);
  const [itemsCopy, setItemsCopy] = useState([]);
  const [totalCopy, setTotalCopy] = useState("");
  const [updateCounter, setUpdateCounter] = useState(0);
  const debouncePostUpdate = useDebounce(updateCounter, 500);

  const prevCartRef = useRef();

  const items = cart.items;

  const postData = useMemo(() => ({
    eps: items.map((item, index) => ({
      guid: item.guid,  
      nominal: item.nominal,
      quantity: item.quantity,
      index_number: index + 1
    }))
  }), [items]);

  const sendPostRequest = useCallback(() => {
    dispatch(postBasket(postData));
    setUpdateCounter(0);
  }, [dispatch, postData]);

  useEffect(() => {
    if (prevCartRef.current) {
      const hasQuantityChanged = cart.items.some((item, index) => {
        const prevItem = prevCartRef.current.items[index];
        return prevItem && prevItem.quantity !== item.quantity;
      });
      
      const itemsRemoved = prevCartRef.current.items.length > cart.items.length;

      if (hasQuantityChanged || itemsRemoved || cart.items.length === 0) {
        setUpdateCounter(prev => prev + 1);
      }
    }
    prevCartRef.current = cart;
  }, [cart]);

  useEffect(() => {
    if (debouncePostUpdate > 0) {
      sendPostRequest();
    }
  }, [debouncePostUpdate, sendPostRequest]);

  useEffect(() => {
    dispatch(getBasket()).then(res => {
      if (res.payload.success && res.payload.data.eps) {
        const data = res.payload.data.eps;
        const totalAmount = data.reduce((sum, item) => sum + +item.nominal * +item.quantity, 0);
        dispatch(addToCart({
          eps: data.map(item => ({
            ...item,
            id: item.index_number,
            tax: +taxSettings?.user || 0
          })),
          totalAmount: totalAmount
        }))
      }
    })
  }, [dispatch, taxSettings?.user])

  if (showOrderComplete) {
    return (
      <OrderComplete
        items={itemsCopy}
        setShowOrderComplete={setShowOrderComplete}
        setShowOrderProcess={setShowOrderProcess}
        total={totalCopy}
      />
    );
  }

  return (
    <div>
      <Header title="Корзина" />
      <ErrorModal messages={messages} setMessages={setMessages} />
      {showOrderProcess ? (
        <OrderProcess
          items={[...items]}
          setItemsCopy={setItemsCopy}
          setTotalCopy={setTotalCopy}
          setShowOrderComplete={setShowOrderComplete}
          setShowOrderProcess={setShowOrderProcess}
          setGetError={setGetError}
        />
      ) : null}
      {getError ? (
        <ErrorModal messages={getError} setMessages={setGetError} />
      ) : null}
      <GoBack />
      <MaxOrder user={user} listLang={listLang} />
      <ClearCart />
      <div className="cart__main-content">
        <List items={items} />
        <Order 
          cart={cart}
          user={user}
          ndfl={ndfl}
          listLang={listLang}
          messages={messages}
          setMessages={setMessages}
          setShowOrderProcess={setShowOrderProcess}
        />
      </div>
    </div>
  );
};

export default Cart;
