import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { stateUpdate } from "../utils";
import { getInitialState } from "../../utils/helpers";
import ax from "../../utils/ax";

const initialState = getInitialState('ndflState') || {
  first_name: "",
  last_name: "",
  patronymic: "",
  date_of_birth: '',
  status: "",
  status_text: "",
  created_at: '',
  taxes_id: null,
  country: "",
  idLandForLeavingModal: 'home',
  visibleWherePromptVisible: false,
};

export const getNDFLStatus = createAsyncThunk(
  "taxes/get-status",
  async () => {
    try {
      const {data} = await ax().get('taxes/get-status', {})
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const postNDFLForm = createAsyncThunk(
  "taxes/edit",
  async (ndflData) => {
    try {
      const formData = new FormData();

      for (let key in ndflData) {
        formData.append(key, ndflData[key]);
      }

      const {data} = await ax().post('taxes/edit', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      return data;
    } catch (error) {
      return error;
    }
  }
);

const ndflSlice = createSlice({
  name: "ndfl",
  initialState,
  reducers: {
    ndflUpdate(state, action) {
      stateUpdate(state, action.payload);
    },
    setIdLandForLeavingModal(state, action) {
      state.idLandForLeavingModal = action.payload;
    },
    setVisibleWherePromptVisible(state, action) {
      state.visibleWherePromptVisible = action.payload;
    },
  },
});

export const { ndflUpdate, setIdLandForLeavingModal, setVisibleWherePromptVisible } = ndflSlice.actions;

export default ndflSlice.reducer;
