import React from "react";

import renderPopUpText from "./index";
import { closeModal } from "../../utils/helpers";

import "./ndfl_modal.css"

const NDFLModal = ({lang, listLang, setVisibleInfoToNDFL}) => {
  return (
    <div className="ndfl-modal__container">
      <div className="ndfl-modal__content ">
        <img
          onClick={() => {
            setVisibleInfoToNDFL(false);
            closeModal();
          }}
          alt="img"
          src={require("../../assets/icons/close.png")}
          className="ndfl-modal__icon"
        />
        <h3 className={'ndfl-modal__title'}>Что такое {listLang?.taxSystem} и почему его нужно платить</h3>
        {renderPopUpText(lang)}
      </div>
    </div>
  );
};

export default NDFLModal;