import React, { memo } from "react";
import { useDispatch } from "react-redux";

import { openModal } from "../../../utils/helpers";
import { addToCart, postBasket } from "../../../redux/reducers/cartReducer";

import { ReactComponent as Cart } from '../../../assets/iconSvg/cartWithArrow.svg';

const AddToCart = memo(({
  taxSettings,
  items,
  cart,
  listLang,
  item,
  setMessages,
  messages,
  scrollWidth,
  setIsPosted
}) => {
  const dispatch = useDispatch();

  const selectedItems = items.filter(item => item.amount > 0);
  const ndflTax = parseFloat(taxSettings?.user) || 0;
  const commissionUser = parseFloat(item.user_markup) || 0;

  const resultSums = () => {
    let total = 0;
    let totalWithoutCommission = 0;
    let totalCommission = 0;
    let ndfl = 0;
    let incomeTax = taxSettings?.user || 0;
    let userDiscount = 0;
    
    items.forEach((eps) => {
      const { price, user_markup, user_discount, amount } = eps;
      
      if (user_markup) {
        const commission = (price * parseFloat(user_markup) / 100) * amount;
        totalCommission += commission;
        totalWithoutCommission += price * amount;
      } else {
        totalWithoutCommission += price * amount;
      }
      
      if (user_discount) {
        userDiscount = parseFloat(user_discount) / 100;
      }
    });
  
    if (taxSettings?.user) {
      ndfl = Math.round((totalWithoutCommission + totalCommission) * (incomeTax / 100) / (1 - incomeTax / 100));
    }
  
    total = totalWithoutCommission + totalCommission + ndfl;
    
    total = total * (1 - userDiscount);
    
    total = Math.round(total);
  
    return { total, ndfl, totalCommission };
  };

  const {total, ndfl, totalCommission} = resultSums();

  const handleClickSend = (total) => {
    if (selectedItems.length > 0) {
      const updatedCartItems = [...cart.items];

      selectedItems.forEach((epsItem) => {
        const existIndex = updatedCartItems.findIndex(item => 
          item.guid === epsItem.guid && item.nominal === epsItem.price
        );

        if (existIndex !== -1) {
          updatedCartItems[existIndex] = {
            ...updatedCartItems[existIndex],
            quantity: updatedCartItems[existIndex].quantity + epsItem.amount
          };
        } else {
          updatedCartItems.push({
            guid: epsItem.guid || epsItem.guid_1c,
            available: epsItem.available,
            nominal: epsItem.price,
            quantity: epsItem.amount,
            name: epsItem.name,
            warning: epsItem.isNew ? "Под заказ 3-5 дней" : "Из наличия - в течении 10 минут",
            demo: epsItem.demo,
          });
        }
      });
      
      const data = {
        eps: updatedCartItems.map((epsItem, index) => ({
          guid: epsItem.guid || item.guid_1c,  
          nominal: epsItem.nominal,
          quantity: epsItem.quantity,
          index_number: index + 1
        }))
      };

      dispatch(postBasket(data)).then(res => {
        if (res.payload.success) {
          const data = res.payload.data.eps.map(item => item);
          const totalAmount = data.reduce((sum, item) => sum + +item.nominal * +item.quantity, 0);
          dispatch(addToCart({
            eps: data.map((epsItem, index) => {
              const selectedItem = selectedItems.find(item => item.guid === epsItem.guid && item.id === epsItem.nominal);
              const existingCartItem = cart.items.find(item => item.guid === epsItem.guid && item.id === epsItem.nominal);

              return {
                ...epsItem,
                id: epsItem.index_number,
                name: epsItem.data.name,
                reserve: selectedItem?.available ?? existingCartItem?.reserve ?? 0,
                warning: epsItem.isNew
                  ? "Под заказ 3-5 дней"
                  : "Из наличия - в течении 10 минут",
                amount: epsItem.quantity,
                tax: +taxSettings?.user,
                total: epsItem.nominal * epsItem.quantity,
              }
            }),
            totalAmount: totalAmount
          }))

          setMessages([
            ...messages,
            {
              title: "В корзину добавлены сертификаты",
              message: <> «{item.name}» на&nbsp;{total}&nbsp;{listLang.symbol} </>,
              firstColor: "#000",
              secondColor: "#000",
            },
          ]);
          
          items.forEach(item => {
            item.amount = 0;
          });
          setIsPosted(true);
        } else {
          setMessages([
            ...messages,
            {
              title: res.payload.response.data.error || "Произошла ошибка",
              message: '',
              firstColor: "#000",
              secondColor: "#000",
            },
          ]);
        }
      })

      openModal(scrollWidth);
    }
  };

  return (
    <div className="prize__result-container">
      {ndflTax ? (
        <div className="prize__result-content prize__result-content-ndfl">
          <span className="prize__result-title_s">НДФЛ</span>{" "}
          <b className="prize__result-total">
            {ndfl}
          </b>{" "}
          {listLang.symbol}
        </div>
      ) : null}
      {commissionUser ? (
        <div className="prize__result-content">
          <span className="prize__result-title_s">Комиссия</span>{" "}
          <b className="prize__result-total">
            {totalCommission}
          </b>{" "}
          {listLang.symbol}
        </div>
      ) : null}
      <div className="prize__result-content prize__result-content-total">
        <span className="prize__result-title_m">Итого</span>{" "}
        <b className="prize__result-total">
          {total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
        </b>{" "}
        {listLang.symbol}
      </div>
      <button
        className={`button prize__result-button`}
        onClick={() => handleClickSend(total)}>
        Добавить в корзину
        <Cart className="prize__result-button-icon" />
      </button>
    </div>
  );
});

AddToCart.displayName = 'AddToCart';

export default AddToCart;