import { createSlice } from "@reduxjs/toolkit";

import { stateUpdate } from "../utils";

const initialState = {
  lang: 'ru',
  taxSystem: 'НДФЛ',
  countrySymbol: 'RU',
  symbol: '₽',
};

const listlangSlice = createSlice({
  name: "listLang",
  initialState,
  reducers: {
    setLang(state, action) {
      state.lang = action.payload;
    },
    setListLang(state, action) {
      stateUpdate(state, action.payload);
    },
  },
});

export const { setLang, setListLang } = listlangSlice.actions;

export default listlangSlice.reducer;
