import { useSelector } from "react-redux";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

import { formatNumber } from "../../utils/helpers";

import { ReactComponent as Cart } from '../../assets/iconSvg/cart.svg';

import "./Header.css";

const Header = (props) => {
  const navigate = useNavigate();
  const title = props.title || "Витрина призов";

  const user = useSelector((state) => state.user);
  const listLang = useSelector((state) => state.listLang);
  const lang = useSelector((state) => state.listLang.lang);
  const cart = useSelector((state) => state.cart);

  const isMobile = useMediaQuery({ maxWidth: 768 });

  if (isMobile) {
    return (
      <div>
        <div className="header__container">
          <div className="header__block">
            <div className="header__text">
              <a className="header__link" onClick={() => navigate("/profile")}>
                Личный&nbsp;кабинет
              </a>
              баллы:{" "}
              <span
                className="header__points"
                onClick={() => navigate("/cart")}>
                {formatNumber(user.points)}
              </span>
            </div>
            {
              lang !== 'by' ? (
                <div className="header__text header__cart" onClick={() => navigate("/cart")}>
                  <Cart className="header__cart-icon" width={13} height={13}/>
                  <a className="header__link header__cart-link">
                    {formatNumber(cart.total)}&nbsp;{listLang.symbol + " "}
                  </a>
                  <p className="header__cart-items-length">
                    ({cart.items.length})
                  </p>
                </div>
              ) : null
            }
          </div>
          <h1 className="header__title">{title}</h1>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="header__container">
        <h1 className="header__title">{title}</h1>
        <div className="header__block">
          <a className="header__link" onClick={() => navigate("/profile")}>Личный кабинет</a>
          <div className="header__text">
            баллы:{" "}
            <span
              className="header__points"
              onClick={() => navigate("/cart")}>
              {user.points?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
            </span>
          </div>
          {
            lang !== 'by' ? (
              <div className="header__text header__cart" onClick={() => navigate("/cart")}>
                <Cart className="header__cart-icon" />
                <a className="header__link">
                  {formatNumber(cart.total)}&nbsp;{listLang.symbol + " "}
                </a>
                <p className="header__cart-items-length">
                  ({cart.items.length})
                </p>
              </div>
            ) : null
          }
        </div>
      </div>
    </div>
  );
};

export default Header;
