import React, { memo } from "react";

import PrizeItem from "./PrizeItem";

const List = memo(({items, isPosted, setItems, user, showCommission, listLang}) => {
  return (
    <div key={items} className="prize__list-items-container">
      <div className="prize__list-items-content">
        <div className={`
          prize__nominal-header 
          ${user.isDemo ? 'prize__nominal-header_is-commission' : ''}
        `}>
          Номинал
        </div>
        {showCommission  ? (
          <div className="prize__commission-header">Комиссия</div>
        ) : null}
        <div className="prize__amount-header">Количество</div>
        <div className="prize__total-header">Итого</div>
      </div>
      <div className={'prize__list-items-wrapper'}>
        {items.map(eps => (
          <PrizeItem
            items={items}
            key={eps.id}
            item={eps}
            setItems={setItems}
            user={user} 
            listLang={listLang} 
            isPosted={isPosted}
          />
        ))}
      </div>
    </div>
  );
});

List.displayName = 'List';

export default List;