import React, { useState, useEffect } from "react";

import { ReactComponent as ArrowUp } from '../../assets/iconSvg/go-up.svg';

import "./GoUp.css";

const GoUp = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <ArrowUp
      onClick={scrollToTop}
      className={`go-up ${isVisible ? 'active' : ''}`}
    />
  );
};

export default GoUp;