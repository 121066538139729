import React, { memo, useEffect, useRef, useState } from "react";

import { ReactComponent as Arrow } from '../../../assets/iconSvg/arrow.svg';

const Categories = memo(({
  categories, 
  selectedCategories, 
  setSelectedCategories,
  clickSelectedItem
}) => {
  const [visibleCategory, setVisibleCategory] = useState(false);
  const categoryRef = useRef(null);

  const handleVisibleCategory = () => {
    setVisibleCategory(innerVisibleCategory => !innerVisibleCategory);
  };

  useEffect(() => {
    if (!visibleCategory) return;

    const handleClick = (e) => {
      if (!categoryRef.current) return;
      if (!categoryRef.current.contains(e.target)) {
	      setVisibleCategory(false);
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    }
  }, [visibleCategory]);

  return (
    <div ref={categoryRef} className={'prizes-list__category'}>
      <div
        className={`
          prizes-list__category-container
          ${visibleCategory ? 'prizes-list__category-container_active' : ''}
        `}
        onClick={handleVisibleCategory}>
        <span className={'prizes-list__category-label'}>
          {selectedCategories.length > 0 ? `${selectedCategories.join(', ').slice(0, 30)}...` : 'Категории'}
        </span>
        <Arrow className={`prizes-list__category-arrow ${visibleCategory ? 'prizes-list__category-arrow_active' : ''}`} />
      </div>
      <ul className={`prizes-list__category-list ${visibleCategory ? 'prizes-list__category-list_active' : ''}`}>
        <li className={'prizes-list__category-item'} onClick={() => setSelectedCategories([])}>Все</li>
        {categories.map((item) => (
          <li
            className={`
              prizes-list__category-item
              ${selectedCategories.includes(item.name) ? 'prizes-list__category-item_active' : ''}
            `}>
            <input
              type="checkbox"
              id={item.name}
              className={'prizes-list__category-input'}
              checked={selectedCategories.includes(item.name)}
            />
            <label
              htmlFor={item.name}
              onClick={() => clickSelectedItem(item)}>
              <span className="prizes-list__custom-checkbox" />
              <span>{item.name}</span>
            </label>
          </li>
        ))}
      </ul>
    </div>
  )
});

Categories.displayName = 'Categories';

export default Categories;