import React, { memo } from "react";
import { useMediaQuery } from "react-responsive";

import Button from "../../../components/Button/Button";
import { formatNumber, getScrollbarWidth, openModal } from "../../../utils/helpers";

const Order = memo(({cart, user, ndfl, listLang, setMessages, messages, setShowOrderProcess}) => {
  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  const scrollWidth = getScrollbarWidth();

  const getNoun = (number, one, two, five) => {
    let n = Math.abs(number);
    n %= 100;

    if (n >= 5 && n <= 20) {
      return five;
    }

    n %= 10;

    if (n === 1) {
      return one;
    }

    if (n >= 2 && n <= 4) {
      return two;
    }

    return five;
  };

  const handleClickOpenModalOrder = () => {
    openModal(scrollWidth);

    if (cart.total === 0) {
      return setMessages([
        ...messages,
        {
          title: "Корзина пуста",
          message: "",
          firstColor: "#000",
          secondColor: "#000",
        },
      ]);
    }
    if (cart.total - user.points > 0) {
      return setMessages([
        ...messages,
        {
          title: "Недостаточно баллов",
          message: "",
          firstColor: "#000",
          secondColor: "#000",
        },
      ]);
    }

    setShowOrderProcess(true);
  };

  if (isTablet) {
    return (
      <div className="cart__order-container">
        <div className="cart__order-wrapper">
          <b className="cart__order-title">В корзине</b>
          <a className="cart__order-link">
            {
              cart.items.length
              + ' ' +
              getNoun(cart.items.length, 'сертификат', 'сертификата', 'сертификатов')
            }
          </a>
          <span className="cart__order-total-title">
            Общая&nbsp;стоимость&nbsp;
            <b className="cart__order-total">
              {formatNumber(cart.total)}&nbsp;{listLang.symbol}
            </b>
          </span>
          <Button
            onClick={handleClickOpenModalOrder}
            customClass="button_cart"
            title="Перейти к оформлению"
            disabled={cart.total > user.points || ndfl.status !== "confirmed"}
          />
        </div>
      </div>
    );
  }
  return (
    <div className="cart__order-container">
      <b className="cart__order-title">В корзине</b>
      <a className="cart__order-link">
        {
          cart.items.length
          + ' ' +
          getNoun(cart.items.length, 'сертификат', 'сертификата', 'сертификатов')
        }
      </a>
      <span className="cart__order-total-title">Общая стоимость</span>
      <b className="cart__order-total">
        {cart.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' ' + listLang.symbol}
      </b>
      <Button
        customClass={`button_cart`}
        title="Перейти к оформлению"
        onClick={handleClickOpenModalOrder}
        disabled={cart.total > user.points || ndfl.status !== "confirmed"}
      />
    </div>
  );
});

Order.displayName = 'Order';

export default Order;