import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import Button from "../../Button/Button";
import { getCard } from "../../../redux/reducers/prizesReducer";

const Prize = memo(({item, idx}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSendData = (item) => {
    dispatch(getCard({guid: item})).then(res => {
      if(res.payload.success) {
        const data = res.payload.data;
        navigate("/certificate", { state: { data } })
      }
    })
  };

  return (
    <div
      className="prizes-list__box-container"
      onClick={() => handleSendData(item.guid_1c)}
      key={idx}>
      <div className="prizes-list__box-wrapper">
        <div>
          <img alt="img" src={item.imageUrl} className="prizes-list__box-image" />
          <div className={'prizes-list__box-info'}>
            <b className="prizes-list__box-title">{item.name}</b>
          </div>
        </div>
        <div>
          <p className="prizes-list__box-category">
            Категория: <span className="prizes-list__box-category-value">{item.category}</span>
          </p>
          <Button
            customClass="button_prize prizes-list__box-button"
            title="Выбрать номинал"
          />
        </div>
      </div>
    </div>
  );
});

Prize.displayName = 'Prize';

export default Prize;