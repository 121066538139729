import React from "react";
import { useSelector } from "react-redux";

import Header from "../components/Header/Header";
import NDFL from "../components/NDFL/NDFL";
import ProfileForm from "../components/ProfileForm/ProfileForm";
import GoBack from "../components/GoBack/GoBack";

const Profile = () => {
  const ndfl = useSelector((state) => state.ndfl);

  return (
    <div>
      <Header title="Личный кабинет" />
      <GoBack />
      {ndfl.status !== "confirmed" ? <NDFL /> : null}
      <ProfileForm />
    </div>
  );
};

export default Profile;
