import React, { memo } from "react";

import { findLink } from "../../../redux/utils";

const Details = memo(({item}) => {
  return (
    <>
      <p className="prize__details-title">
        Срок действия:
        <b className="prize__details-text">
          {item.validity_period}
        </b>
      </p>
      <p className="prize__details-title">
        Место приема:
        <a 
          className="prize__details-text prize__details-link" 
          target="_blank" 
          rel='noreferrer' 
          href={item.reception_locations}>
          {findLink(item.reception_locations)}
        </a>
      </p>
    </>
  );
});

Details.displayName = 'Details';

export default Details;