import { useSelector, useDispatch } from "react-redux";
import React, { useState } from "react";
import MaskedInput from "react-text-mask";
import moment from "moment";

import Button from "../Button/Button";
import AcceptedModal from "../AcceptedModal/AcceptedModal";
import { updateUser, createOrder } from "../../actions/widgetActions";
import { getScrollbarWidth, handleSumCommissionBy, openModal } from "../../utils/helpers";
import ErrorModal from "../ErrorModal/ErrorModal";

import { ReactComponent as Warning } from '../../assets/iconSvg/warning.svg';

import "./bank_card_by.css";

const BankCard = () => {
  const [card, setCard] = useState("");
  const [count, setCount] = useState("");
  const [messages, setMessages] = useState([]);
  const [cardWarning, setCardWarning] = useState(false);
  const [sumWarning, setSumWarning] = useState(false);
  const [isInfoToAccepted, setIsInfoToAccepted] = useState(false);

  const orders = useSelector((state) => state.orders);
  const user = useSelector((state) => state.user);
  const listLang = useSelector((state) => state.listLang);
  const dispatch = useDispatch();

  const scrollWidth = getScrollbarWidth();
  const total = !user.isDemo ? count : handleSumCommissionBy(count);

  function ch(t) {
    let a = t.target.value;
    if (a.length === 0) {
      setCount(a);
      handleSumWarning(a);
    } else {
      if (a.length === 1 && a[0] === ".") {
      } else {
        if (
          !a.substring(0, a.length - 1).includes(".") &&
          a[a.length - 1] === "."
        ) {
          setCount(a);
          handleSumWarning(a);
        } else {
          setCount(parseFloat(a) + "");
          handleSumWarning(parseFloat(a) + "");
        }
      }
    }
  }

  const handleClickInfoToAccepted = () => {
    let date = new moment(new Date()).format("DD.MM.YYYY");
                
    dispatch(updateUser({points: user.points - (parseInt(total) || 0)}));

    let order = {
      id: orders.items.length + 1,
      date,
      price: parseInt(total) || 0,
      type: "card",
      items: [
        {
          name: card,
          id: 0,
          status: "pending",
          price: parseInt(count) || 0,
          commission: 10,
        },
      ],
    };
    dispatch(createOrder({ item: order }));

    setCard('');
    setCount('');
    setMessages([
      {
        title: "Платеж успешно выполнен",
        message: "",
        firstColor: "#000",
        secondColor: "#000",
      },
    ]);
    setIsInfoToAccepted(false);
  };

  const handleClickButtonSend = () => {
    handleCardWarning();
    handleSumWarning();
    
    if (!card || !parseInt(count)) return;
    if (card.replace(/_/g, '').length !== 19) return;
    if ((parseInt(count) || 0) > listLang.maxPaymentBankValue) return;
    if ((parseInt(count) || 0) > user.points) return;
    
    openModal(scrollWidth);
    setIsInfoToAccepted(true);
  };

  const handleCardWarning = (propsCard) => {
    const currentValue = propsCard ? propsCard : card;

    if (!currentValue || currentValue.includes('_')) {
      setCardWarning(true);
      return;
    }

    setCardWarning(false);
  };

  const handleSumWarning = (propsSum) => {
    const currentValue = propsSum ? propsSum : total;

    if (!currentValue.length) {
      setSumWarning(true);
      return;
    }

    setSumWarning(false);
  };

  const renderCardNumber = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <b className="bank-card-by__field-header">Номер карты</b>
        <MaskedInput
          value={card}
          type="tel"
          placeholder="0000 0000 0000 0000"
          className={`input input_number bank-card-by__card-number ${cardWarning ? 'input_warning' : ''}`}
          mask={[
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            " ",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            " ",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            " ",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onChange={(t) => {
            setCard(t.target.value);
            handleCardWarning(t.target.value);
          }}
        />
        {
          cardWarning
            ? <b className="bank-card-by__warning">Неверный номер банковской карты</b>
            : <br />
        }
      </div>
    );
  };

  const renderCount = () => {
    return (
      <div className={'bank-card-by__pay-form_count'}>
        <b className="bank-card-by__field-header">Сумма перевода</b>
        <input
          type="number"
          value={count}
          placeholder={listLang.symbol}
          className={`
            input
            input_count
            bank-card-by__input_count
            ${
              sumWarning ||
              count.length > 0 && parseFloat(total) - listLang.maxPaymentBankValue > 0 || parseInt(total) > user.points
                ? 'input_warning'
                : ''
            }
          `}
          onChange={(t) => ch(t)}
          onKeyPress={(event) => {
            if (!(/[0-9]/.test(event.key) || event.key === ".")) {
              event.preventDefault();
            }
          }}
          onBlur={() => setCount(count) }
        />
        {!user.isDemo && count > 0 ? (
          <span
            className={`
              input_count-tip 
              ${sumWarning || parseFloat(total) - listLang.maxPaymentBankValue > 0 || parseInt(total) > user.points
                ? "input_count-tip_warning"
                : ""
              }
            `}>
          </span>
        ) : null}
        {
          sumWarning
            ? <b className="bank-card-by__warning">Неверная сумма</b>
            : count.length > 0 && parseFloat(count) - listLang.maxPaymentBankValue > 0
              ? <b className="bank-card-by__warning">не больше {listLang.maxPaymentBankValue} {listLang.symbol} за один платеж</b>
              : parseInt(total) > user.points
                ? <b className="bank-card-by__warning">недостаточно баллов</b>
                : <br/>
        }
      </div>
    );
  };

  const renderCommission = () => {
    return (
      <div className={'bank-card-by__pay-form_count'}>
        <div className="bank-card-by__header-container">
          <b className="bank-card-by__field-header bank-card-by__input-title">Комиссия</b>
          <Warning />
        </div>
        <input
          type="number"
          value={Math.trunc((count / 100) * 10)}
          disabled
          placeholder={listLang.symbol}
          className={`
            input
            input_count
            bank-card-by__input_count
            bank-card-by__commission
            ${
            sumWarning ||
            count.length > 0 && parseFloat(total) - listLang.maxPaymentBankValue > 0 || parseInt(total) > user.points
              ? 'input_warning'
              : ''
            }
          `}
          onChange={(t) => ch(t)}
          onKeyPress={(event) => {
            if (!(/[0-9]/.test(event.key) || event.key === ".")) {
              event.preventDefault();
            }
          }}
          onBlur={() => setCount(count) }
        />
      </div>
    );
  };

  const renderCommissionTax = () => {
    return (
      <div className={'bank-card-by__pay-form_count'}>
        <div className="bank-card-by__header-container">
          <b className="bank-card-by__field-header bank-card-by__input-title">{listLang.taxSystem}</b>
          <Warning />
        </div>
        <input
          type="number"
          value={Math.trunc((count / 0.87) - count)}
          disabled
          placeholder={listLang.symbol}
          className={`
            input
            input_count
            bank-card-by__input_count
            bank-card-by__commission
            ${
            sumWarning ||
            count.length > 0 && parseFloat(total) - listLang.maxPaymentBankValue > 0 || parseInt(total) > user.points
              ? 'input_warning'
              : ''
            }
          `}
          onChange={(t) => ch(t)}
          onKeyPress={(event) => {
            if (!(/[0-9]/.test(event.key) || event.key === ".")) {
              event.preventDefault();
            }
          }}
          onBlur={() => setCount(count) }
        />
      </div>
    );
  };

  const renderWarning = () => {
    return (
      <div className="bank-card-by__field-error">
        <img
          alt="img"
          src={require("../../assets/icons/RubleWarning.png")}
          style={{ height: "24px", marginRight: "10px" }}
        />
        Максимальная сумма перевода за один платеж {listLang.maxPaymentBankText}{listLang.symbol}.
      </div>
    );
  };

  const renderSend = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Button
          customClass="button_send bank-card-by__button"
          onClick={handleClickButtonSend}
          title="Отправить"
        />
        <br/>
      </div>
    );
  };

  return (
    <div>
      {renderWarning()}
      <div className="bank-card-by__container">
        <ErrorModal messages={messages} setMessages={setMessages} />
        {renderCardNumber()}
        <br />
        <div className={'bank-card-by__count-container'}>
          {renderCount()}
          {
            user.isDemo ? (
              <>
                {renderCommission()}
                {renderCommissionTax()}
              </>
            ) : null
          }
        </div>
        <br />
        <div className={'bank-card-by__total-container'}>
          <p className={'bank-card-by__total'}>
            Итого: <span className={'bank-card-by__sum'}>{total}</span> {listLang.symbol}
          </p>
          <span className={'bank-card-by__sum-info'}>Сумма будет списано с баланса баллов</span>
        </div>
        {renderSend()}
        {isInfoToAccepted ? (
          <AcceptedModal
            card={card}
            count={total}
            type={'card'}
            setIsInfoToAccepted={setIsInfoToAccepted}
            handleClickInfoToAccepted={handleClickInfoToAccepted}
          />
        ) : null}
      </div>
    </div>
  );
};

export default BankCard;
