import axios from 'axios';
import env from '../env/env.json';

const ax = () => {
  let apiId = localStorage.getItem("apiId");
  let token = localStorage.getItem("userRefreshToken");

  const ax = axios.create({
    baseURL: env.baseURL,
    timeout: 60000,
    headers: {
      'Content-Type': 'application/json',
      "API-ID": apiId,
      'Authorization': `Bearer ${token}`,
    },
  });
  return ax;
};

export default ax;