import React from "react";

import './ShowMore.css';

const ShowMore = ({title, handleOnClick}) => {
  return (
    <div className="show-more" onClick={handleOnClick}>
      <p>{title}</p>
      <img
        alt="img"
        src={require("../../assets/icons/refresh.png")}
        className="show-more__icon"
      />
    </div>
  );
};

export default ShowMore;