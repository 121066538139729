import React, {useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import NDFLModal from "../NDFLModal/NDFLModal";
import { getScrollbarWidth, openModal } from "../../utils/helpers";

import "./ndfl.css";

const NDFL = () => {
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);
  const listLang = useSelector((state) => state.listLang);
  const lang = useSelector((state) => state.user.lang);

  const [visibleInfoToNDFL, setVisibleInfoToNDFL] = useState(false);
  const scrollWidth = getScrollbarWidth();
  const isLang = lang === 'by' || lang === 'kz';

  if (user.isDemo) {
    return null;
  }

  const handleClickLink = () => {
    setVisibleInfoToNDFL(true);
    openModal(scrollWidth);
  };

  return (
    <div className="ndfl__container">
      <div className="ndfl__header-content">
        <img
          alt="img"
          src={require("../../assets/icons/md-clipboard-info.png")}
          className="ndfl__icon"
        />
        <p className="ndfl__header">Анкета {listLang?.taxSystem} не заполнена</p>
      </div>
      <p className="ndfl__text">
        Для заказа подарка необходимо заполнить все поля{" "}
        <a className="ndfl__link-text" onClick={() => navigate('/ndfl')}>
          Анкеты {listLang?.taxSystem}
        </a>
        . Это нужно, чтобы мы заплатили за Вас налог на доходы физических лиц в
        соответствии с законодательством {listLang?.fullCountryName}.
      </p>
      <p className="ndfl__link">
        <a
          onClick={handleClickLink}
          className="ndfl__link-text">
          Что такое {listLang?.taxSystem} и почему его нужно оплатить?
        </a>
      </p>

      {visibleInfoToNDFL && <NDFLModal lang={lang} listLang={listLang} setVisibleInfoToNDFL={setVisibleInfoToNDFL} />}
    </div>
  );
};

export default NDFL;
