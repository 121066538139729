import React from "react";

import { ReactComponent as Error } from '../../assets/iconSvg/photo-error.svg';

import "./photo-error.css";

const PhotoError = ({error}) => {
  return (
    <div className={`photo-error__wrapper`}>
      <div className="photo-error__content">
        <div className="photo-error__container-image">
          <Error className="photo-error__image"/>
        </div>
        <div>
          <p className="photo-error__description">{error}</p>
        </div>
      </div>
    </div>
  )
};

export default PhotoError;