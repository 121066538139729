import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import {useSelector} from "react-redux";

import "./top_menu.css";

const TopMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const listLang = useSelector((state) => state.listLang);
  const lang = useSelector((state) => state.listLang.lang);

  const [activeScreen, setActiveScreen] = useState(location.pathname);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const inputReference = useRef(null);

  const menuData = {
    by: [
      { name: "Банковская карта", screen: "/bank_card" },
      { name: "Анкета " + listLang.taxSystem, screen: "/ndfl" },
      { name: "История заказов", screen: "/order_history" },
    ],
    ru: [
      { name: "Электронные сертификаты", screen: "/" },
      { name: "Банковская карта", screen: "/bank_card" },
      { name: "Мобильная связь", screen: "/mobile" },
      { name: "СБП", screen: "/sbp" },
      { name: "Анкета " + listLang.taxSystem, screen: "/ndfl" },
      { name: "История заказов", screen: "/order_history" },
    ],
    kz: [
      { name: "Электронные сертификаты", screen: "/" },
      { name: "Банковская карта", screen: "/bank_card" },
      { name: "Мобильная связь", screen: "/mobile" },
      { name: "Анкета " + listLang.taxSystem, screen: "/ndfl" },
      { name: "История заказов", screen: "/order_history" },
    ],
  };

  let menu = menuData[lang] || [];

  const handleNavigateTo = (item) => {
    setActiveScreen(item.screen);
    navigate(item.screen);
  };

  useEffect(() => {
    const element = document.getElementsByClassName("top-menu__container")[0];

    const handleWheel = (event) => {
      if (event.deltaMode == event.DOM_DELTA_PIXEL) {
        var modifier = 1;
        // иные режимы возможны в Firefox
      } else if (event.deltaMode == event.DOM_DELTA_LINE) {
        var modifier = parseInt(getComputedStyle(this).lineHeight);
      } else if (event.deltaMode == event.DOM_DELTA_PAGE) {
        var modifier = this.clientHeight;
      }
      if (event.deltaY != 0) {
        // замена вертикальной прокрутки горизонтальной
        element.scrollLeft += modifier * event.deltaY;
        event.preventDefault();
      }
    };

    element && element.addEventListener('wheel', handleWheel);

    return () => element && element.removeEventListener('wheel', handleWheel);
  }, []);

  if (isMobile) {
    return (
      <div className="top-menu__container">
        <div className="top-menu__list">
          {menu.map((item) =>
            activeScreen === item.screen ||
            (item.screen === "/" && activeScreen === "/certificate") ? (
              <p
                className="top-menu__item top-menu__item_selected"
                ref={inputReference}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  handleNavigateTo(item);
                }}
                key={item.screen}>
                {item.name}
              </p>
            ) : (
              <p
                className="top-menu__item"
                key={item.screen}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  handleNavigateTo(item)
                }}>
                {item.name}
              </p>
            )
          )}
        </div>
      </div>
    );
  }
  return (
    <div className="top-menu__container">
      {menu.map((item) =>
        activeScreen === item.screen ||
        (item.screen === "/" && activeScreen === "/certificate") ? (
          <p
            className="top-menu__item top-menu__item_selected"
            onClick={() => handleNavigateTo(item)}
            key={item.screen}>
            {item.name}
          </p>
        ) : (
          <p
            className="top-menu__item"
            onClick={() => handleNavigateTo(item)}
            key={item.screen}>
            {item.name}
          </p>
        )
      )}
    </div>
  );
};

export default TopMenu;
