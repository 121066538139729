import React, { memo } from "react";
import { useNavigate } from "react-router-dom";

import { formatNumber } from "../../../utils/helpers";

const MaxOrder = memo(({user, listLang}) => {
  const navigate = useNavigate();

  return (
    <div className="cart__max-order">
      Вы можете выбрать сертификаты на общую сумму
      <a className="cart__max-order_link" onClick={() => navigate("/cart")}>
        {formatNumber(user.points)}&nbsp;{listLang.symbol}
      </a>
    </div>
  );
});

MaxOrder.displayName = 'MaxOrder';

export default MaxOrder;