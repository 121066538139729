import React, { memo } from "react";

import Button from "../../Button/Button";

const DropDown = memo(({
  isMobile, 
  showMenu, 
  type, 
  list, 
  setShowMenu, 
  hoverEffectValue, 
  currentItem,
  setCurrentItem,
  visible,
  handleAccept,
  handleItem
}) => { 
  if (isMobile && showMenu && type && type === 'sbp') {
    return (
      <div className="select__list-menu"> 
        <div className="select__clear-container">
          <p
            onClick={() => {
              setShowMenu(false);
            }}
            className="select__clear-button">
            Отменить
          </p>
          <p className="select__menu-filter-title">Фильтры</p>
          <p onClick={() => setShowMenu(false)} className="select__menu-close">
            <img
              alt="img"
              src={require("../../../assets/icons/close.png")}
              className="select__menu-close-icon"
            />
          </p>
        </div>

        <p className="select__menu-categories-title">Выбрать банк получателя</p>
        <div className="select__menu-container">
          <ul className={'select__list'}>
            {
              list.map((item) => (
                <li 
                  key={item.id} 
                  className={`
                    select__item ${hoverEffectValue ? 'select__item-bank' : ''}
                    ${currentItem.title === item.title ? 'select__item_active' : ''}
                  `} 
                  onClick={() => setCurrentItem(item)}>
                  {item.title}
                </li>
              ))
            }
          </ul>
        </div>

        <Button
          onClick={handleAccept}
          customClass="button_blue select__button_bottom"
          title={"Выбрать"}
        />
      </div>
    )
  }

  return (
    visible ? (
      <div className={`select__dropdown ${hoverEffectValue ? 'select__dropdown-bank_border' : ''}`}>
        <ul className={'select__list'}>
          {
            list.map((item) => (
              <li 
                key={item.id} 
                className={`select__item ${hoverEffectValue ? 'select__item-bank' : ''}`} 
                onClick={() => handleItem(item)}>
                {item.title}
              </li>
            ))
          }
        </ul>
      </div>
    ) : null
  )
});

DropDown.displayName = 'DropDown';

export default DropDown;