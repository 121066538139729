import React, { memo, useState } from "react";

import Categories from "./Categories";

import { ReactComponent as Cross } from '../../../assets/iconSvg/cross.svg';
import { ReactComponent as Search } from '../../../assets/iconSvg/search.svg';

const Filters = memo(({
  searchValue,
  setSearchValue,
  categories,
  isMobile,
  setShowMenu,
  selectedCategories,
  setSelectedCategories,
  clickSelectedItem
}) => {
  const [searchInputFocus, setSearchInputFocus] = useState(false);

  const handleCrossVisible = () => {
    setSearchValue('');
	  setSearchInputFocus(false);
  };

  return (
    <div className="prizes-list__filters-container">
      <div className="prizes-list__search-input-container">
        <input
          className="prizes-list__search-input"
          value={searchValue}
          placeholder="Поиск по каталогу"
          onFocus={() => setSearchInputFocus(true)}
          onBlur={() => !searchValue.length && setSearchInputFocus(false)}
          onChange={(t) => {
            setSearchValue(t.target.value);
          }}
        />

        <div className="prizes-list__search-icon">
          {searchInputFocus ? <Cross onClick={handleCrossVisible} /> : <Search />}
        </div>
      </div>

      {isMobile ? (
        <div className="prizes-list__filter-icon-container">
          <img
            alt="img"
            onClick={() => setShowMenu(true)}
            src={require("../../../assets/icons/filters.png")}
            className="prizes-list__filter-icon"
          />
          {selectedCategories.length > 0 && (
            <div className="prizes-list__selected-count">{selectedCategories.length}</div>
          )}
        </div>
      ) : null}
      {!isMobile ? (
        <Categories 
          categories={categories} 
          selectedCategories={selectedCategories} 
          setSelectedCategories={setSelectedCategories}
          clickSelectedItem={clickSelectedItem} 
        />
      ) : null}
    </div>
  );
});

Filters.displayName = 'Filters';

export default Filters;