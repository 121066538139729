const stateUpdate = (state, payload) => {
    Object.keys(payload).forEach((i) => {
      state[i] = payload[i];
    });
  };

const findLink = (text) => {
  const findProtocol =
    text.search(/(https|http|www)/ig);
  const findSpaceAfterLink =
    text.includes(' ', findProtocol) ? text.indexOf(' ', findProtocol) : text.length;
  const finalLink =
    text.slice(findProtocol, findSpaceAfterLink);
  const addProtocol =
    finalLink.search(/(https|http)/ig) === -1 ? `https://${finalLink}` : finalLink;

  return findProtocol !== -1 ? addProtocol : undefined;
};

export {stateUpdate, findLink}

