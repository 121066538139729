import React, {memo, useEffect, useRef, useState} from "react";
import { useMediaQuery } from "react-responsive";

import DropDown from "./components/DropDown";

import {ReactComponent as Arrow} from "../../assets/iconSvg/arrow.svg";

import "./Select.css"

const Select = memo((
  {
    title, 
    placeholder, 
    visible, 
    setVisible, 
    value, 
    setValue, 
    ndflStatus, 
    list, 
    className, 
    isDisabled,
    type,
    setHover, 
    hoverEffectValue,
    setSelectError,
    errorMessage
  }) => {
  const ref = useRef(null);

  const [warning, setWarning] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const isMobile = useMediaQuery({ maxWidth: 768 });

  useEffect(() => {
    if (!visible) return;

    const handleClick = (e) => {
      if (!ref.current) return;
      if (!ref.current.contains(e.target)) {
        setVisible(false);
        setHover(false);
      }
    }

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    }
  }, [visible]);

  useEffect(() => {
    if(isMobile && !value && type && type === 'sbp') {
      setCurrentItem({});
    }
  }, [value, isMobile, type])

  const handleItem = (item) => {
    setValue(item);
    setSelectError('');
    setVisible(false);
    setHover(false);
  };

  const handleClick = () => {
    if (!ndflStatus && !isDisabled) {
      setVisible((currentVisible) => !currentVisible);
      setHover((currentHover) => !currentHover)
    }
    if(isMobile) {
      setShowMenu(true);
    }
  };

  const handleWarning = () => {
    if (!value) {
      setWarning(true);

      return;
    }

    setWarning(false);
  };

  const handleAccept = () => {
    setValue(currentItem);
    setVisible(false);
    setHover(false);
    setShowMenu(false);
  };

  const renderErrorName = (value) => (
    value.length < 2
      ? <b className="warning">Мало символов</b>
      : value.length > 30
        ? <b className="warning">Много символов</b>
        : <br/>
  );

  return (
    <div className={`select ${className ? className : ''}`} ref={ref}>
      <div className={`select__input ${ndflStatus ? 'reset-cursor' : ''}`}>
        <h3 className={'select__title'}>{title}</h3>
        <div className={'select__container'}>
          <div
            className={`
              select__content ${visible ? 'select__content_active' : ''} 
              ${hoverEffectValue ? 'select__dropdown-bank_border' : ''} 
              ${ndflStatus || isDisabled ? 'select__content_readonly' : ''}
            `}
            onClick={handleClick}>
            <span className={`select__placeholder ${value ? 'select__value' : ''}`}>
              {value ? value.title : placeholder}
            </span>
            <Arrow className={`select__arrow ${visible ? 'select__arrow_active' : ''}`} />
          </div>
          <DropDown 
            isMobile={isMobile}
            showMenu={showMenu}
            type={type}
            list={list}
            setShowMenu={setShowMenu}
            hoverEffectValue={hoverEffectValue}
            currentItem={currentItem}
            setCurrentItem={setCurrentItem}
            visible={visible}
            handleAccept={handleAccept}
            handleItem={handleItem}
          />
        </div>
        {errorMessage ? <b className="select__warning">{errorMessage}</b> : <br/>}
      </div>
    </div>
  );
});

Select.displayName = 'Select';

export default Select;