import React, { useState, useEffect, memo, useCallback } from "react";
import {useSelector} from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

import ErrorModal from "../ErrorModal/ErrorModal";
import RulesModal from "../RulesModal/RulesModal";
import { getScrollbarWidth, isColumnShown } from "../../utils/helpers";

import ImageBlock from "./components/ImageBlock";
import Details from "./components/Details";
import List from "./components/List";
import AddCard from "./components/AddCard";
import AddCardOpen from "./components/AddCardOpen";
import AddToCart from "./components/AddToCart";

import "./prize_details.css";

const Prize = memo((props) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const navigate = useNavigate();
  const scrollWidth = getScrollbarWidth();

  const user = useSelector((state) => state.user);
  const userNdfl = useSelector((state) => state.ndfl);
  const cart = useSelector((state) => state.cart);
  const listLang = useSelector((state) => state.listLang);
  const taxSettings = useSelector((state) => state.settings.payment_settings.tax);

  const item = props.item;

  const createNewArray = () => {
    let price = +item.step > +item.min_nominal ? +item.step : +item.min_nominal;
    const array = [];
    let step = 0;

    if (+item.step === 300) {
      step = 300;

      if (price < 300) {
        price = 300;
      }
    } else if (+item.step < 500) {
      step = 500;

      if (price < 500) {
        price = 500;
      }
    } else {
      step = +item.step;
    }

    for (let i = 0; i < 5; i++) {
      if (i + 1 < 4) {
        array.push({
          id: price + step * i,
          guid: item.guid_1c,
          price: price + step * i,
          user_markup: +item.user_markup,
          incomeTax: +taxSettings?.user || 0,
          user_discount: +item.user_discount,
          available: 3,
          amount: 0,
          isNew: false,
        })
      } else {
        array.push({
          ...array[i - 1],
          id: array[i - 1].price + step * i,
          guid: item.guid_1c,
          price: array[i - 1].price + step * i,
        })
      }
    }

    return array;
  };

  const [cardOpen, setCardOpen] = useState(false);
  const [cPrice, setCPrice] = useState("");
  const [cAmount, setCAmount] = useState("");
  const [messages, setMessages] = useState([]);
  const [items, setItems] = useState(
    (+item.step !== 0 || item.step === null) && item.nominals.length > 0
      ? (
        item.nominals.map((nominal) => ({
          id: nominal.nominal,
          guid: nominal.guid_1c,
          price: +nominal.nominal,
          user_markup: +item.user_markup,
          incomeTax: +taxSettings?.user || 0,
          user_discount: +item.user_discount,
          available: nominal.reserve,
          amount: 0,
          isNew: false,
          demo: user.isDemo,
        }))
      ) : createNewArray()
  );
  const [warningInputNominal, setWarningInputNominal] = useState(false);
  const [warningInputQty, setWarningInputQty] = useState(false);
  const [description, setDescription] = useState(`${item.description.slice(0, 70)}...`);
  const [visibleRulesCertificate, setVisibleRulesCertificate] = useState(false);
  const [visibleConditionsCertificate, setVisibleConditionsRulesCertificate] = useState(false);
  const [rules, setRules] = useState('');
  const [isPosted, setIsPosted] = useState(false);

  const { showCommission } = isColumnShown(items);

  const handleClickOnNewCard = useCallback(() => {
    setCardOpen(!cardOpen);
  }, [cardOpen]);

  useEffect(() => {
    if(item?.limitations) {
      setRules(item.limitations)
    }
    if (isPosted) {
      setIsPosted(false);
    }
  }, [item.limitations, isPosted, items])

  return (
    <div className="prize__main-container">
      {visibleRulesCertificate ? (
        <RulesModal 
          setRules={setVisibleRulesCertificate} 
          item={item.terms_of_use} 
          type={'terms_of_use'}
        />
      ) : null}
      {visibleConditionsCertificate ? (
        <RulesModal 
          setRules={setVisibleConditionsRulesCertificate} 
          item={item.limitations} 
          type={'limitations'}
        />
      ) : null}
      <ErrorModal messages={messages} setMessages={setMessages} />
      {!isMobile ? (
        <ImageBlock 
          item={item}
          isMobile={isMobile}
          scrollWidth={scrollWidth}
          description={description}
          setVisibleRules={setVisibleRulesCertificate}
          setVisibleConditions={setVisibleConditionsRulesCertificate}
          setDescription={setDescription}
        />
      ) : null}
      <div className="prize__main-content">
        <p className="prize__main-title">
          {item.name}
        </p>
        <Details item={item} />
        {isMobile ? (
          <ImageBlock 
            item={item}
            isMobile={isMobile}
            scrollWidth={scrollWidth}
            description={description}
            setVisibleRules={setVisibleRulesCertificate}
            setVisibleConditions={setVisibleConditionsRulesCertificate}
            setDescription={setDescription}
          />
        ) : null}
        {+item.step !== 0 ? (
          cardOpen ? (
            <AddCardOpen
              item={item}
              items={items}
              taxSettings={taxSettings}
              listLang={listLang}
              setItems={setItems}
              cPrice={cPrice}
              setCPrice={setCPrice}
              cAmount={cAmount}
              setCAmount={setCAmount}
              handleClickCard={handleClickOnNewCard}
              warningInputNominal={warningInputNominal}
              setWarningNominal={setWarningInputNominal}
              warningInputQty={warningInputQty}
              setWarningQty={setWarningInputQty}
            />
          ) : ( 
            <AddCard item={item} listLang={listLang} handleClick={handleClickOnNewCard} />
          )) : null}
        <div className="prize__certificates-container">
          <List 
            items={items}
            isPosted={isPosted}
            user={user}
            showCommission={showCommission}
            listLang={listLang}
            setItems={setItems}
          />
          <AddToCart
            taxSettings={taxSettings}
            items={items}
            cart={cart}
            listLang={listLang}
            item={item}
            messages={messages}
            setMessages={setMessages}
            scrollWidth={scrollWidth}
            setItems={setItems}
            setIsPosted={setIsPosted}
          />
        </div>
      </div>
    </div>
  );
});

Prize.displayName = 'Prize';

export default Prize;