import React, { memo } from "react";

import { closeModal } from "../../utils/helpers";

import "./error_modal.css";

const ErrorModal = memo((props) => {
  const { messages, setMessages } = props;
  
  if (!messages.length > 0) {
    return null;
  }

  const handleClickCloseModal = (item) => {
    setMessages(messages.filter((i) => i !== item));
    closeModal();
  };

  return (
    <div className="error-modal__background">
      {messages.map((item, index) => (
        <div key={index} className="error-modal__container">
          <img
            onClick={() => handleClickCloseModal(item)} 
            alt="img"
            src={require("../../assets/icons/close.png")}
            className="error-modal__icon"
          />
          <div className="error-modal__wrapper">
            <div className="wrapper__message wrapper__message-title" style={{ color: item.firstColor }}>{item.title}</div>
            <div className="wrapper__message" style={{ color: item.secondColor }}>{item.message}</div>
          </div>
        </div>
      ))}
    </div>
  );
});

ErrorModal.displayName = 'ErrorModal';

export default ErrorModal;
