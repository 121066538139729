import React, { memo } from "react";

const Rules = memo(({handleClick, setVisible}) => {
  return (
    <div
      className="prize__rules-container"
      onClick={() => handleClick(setVisible)}>
      <img
        alt="img"
        src={require("../../../assets/icons/rules.png")}
        className="prize__rules-img"
      />
      Правила использования <br />
      подарочного сертификата
    </div>
  );
});

Rules.displayName = 'Rules';

export default Rules;