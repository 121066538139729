import React, { memo } from "react";

const RulesText = memo(({handleClick, setVisible}) => {
  return (
    <div
      className="prize__rules-container prize__conditions-container"
      onClick={() => handleClick(setVisible)}>
      <img
        alt="img"
        src={require("../../../assets/icons/limitations.png")}
        className="prize__rules-img"
      />
      Ограничения на <br/> использование сертификата
    </div>
  );
});

RulesText.displayName = 'RulesText';

export default RulesText;