import React from "react";

const Status = ({status}) => {
  if (status === "pending" || status === 'processing') {
    return (
      <div className="order-list__status-wrapper">
        <div className="order-list__status-content order-list__status-content_pending" />
        <span className="order-list__status-text order-list__status-text_pending">
          В обработке
        </span>
      </div>
    );
  }

  if (status === "canceled" || status === 'awaiting_cancellation' || status === 'runtime_error') {
    return (
      <div className="order-list__status-wrapper">
        <img
          alt="img"
          src={require("../../../assets/icons/error.png")}
          className="order-list__status-icon"
        />
        <span className="order-list__status-text order-list__status-text_rejected">
          Отменен
        </span>
      </div>
    );
  }
  
  return (
    <div className="order-list__status-wrapper">
      <div className="order-list__status-content order-list__status-content_confirmed" />
      <span className="order-list__status-text order-list__status-text_confirmed">
        Завершен
      </span>
    </div>
  );
};

export default Status;