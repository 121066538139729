import React from "react";

import "./Button.css";

const Button = (props) => {
  const { customClass, onClick, title, disabled } = props;

  return (
    <button
      className={customClass ? "button " + customClass : "button"}
      onClick={onClick}
      disabled={disabled}>
      {title}
    </button>
  );
};

export default Button;

