import { useSelector, useDispatch } from "react-redux";
import React, {useEffect, useState} from "react";
import { useMediaQuery } from "react-responsive";

import NDFLStatus from "../NDFLStatus/NDFLStatus";
import ErrorModal from "../ErrorModal/ErrorModal";
import { ndflUpdate } from "../../actions/widgetActions";
import Button from "../Button/Button";
import LeavingModal from "../LeavingModal/LeavingModal";
import Select from "../Select/Select";
import { getPhotoUrl, getScrollbarWidth, openModal } from "../../utils/helpers";

import {ReactComponent as Trash} from "../../assets/iconSvg/trash-white.svg";

import "./ndfl_form_kz.css";

const NDFLForm = () => {
  const dispatch = useDispatch();

  const isMobile = useMediaQuery({ maxWidth: 768 });
  const ndfl = useSelector((state) => state.ndfl);
  const [first_name, setfirst_name] = useState(ndfl.first_name);
  const [patronymic, setMiddleName] = useState(ndfl.patronymic);
  const [last_name, setLastName] = useState(ndfl.last_name);
  const [inn, setInn] = useState(ndfl.inn);
  const [numberDocument, setNumberDocument] = useState(ndfl.number_documents);
  const [files, setFiles] = useState({});
  const [messages, setMessages] = useState([]);

  const [lastNameError, setLastNameError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [patronymicError, setPatronymicError] = useState(false);
  const [innError, setInnError] = useState(false);
  const [visibleDropDownIdentityDocument, setVisibleDropDownIdentityDocument] = useState(false);
  const [visibleDropDownCountry, setVisibleDropDownCountry] = useState(false);
  const [identityDocument, setIdentityDocument] = useState(ndfl.identity_document);
  const [country, setCountry] = useState(ndfl.country);
  const [ndflStatus, setNdflStatus] = useState(ndfl.status_type);
  const scrollWidth = getScrollbarWidth();

  const identityDocumentList = [
    {id: 0, title: 'Паспорт гражданина Республики Казахстан'},
    {id: 1, title: 'Удостоверение личности Республики Казахстан'},
    {id: 2, title: 'Вид на жительство'},
  ];
  const countryList = [
    {id: 0, title: 'Казахстан'},
    {id: 1, title: 'Россия'},
  ];

  const wherePromptVisible =
    ndfl.first_name !== first_name ||
    ndfl.patronymic !== patronymic ||
    ndfl.last_name !== last_name ||
    ndfl.inn !== inn;

  const allFieldAreField =
    lastNameError ||
    firstNameError ||
    patronymicError ||
    innError ||
    !country ||
    !identityDocument ||
    !numberDocument;

  const handleFilesChange = (e, fileKey) => {
    if (e.target.files) {
      const preImg = getPhotoUrl(e.target.files[0]);
      preImg.onload = () => setFiles({...files, [fileKey]: preImg.result});
    }
  };

  const handleClickSend = () => {
    if (allFieldAreField) return;

    dispatch(
      ndflUpdate({
        first_name,
        last_name,
        patronymic,
        inn,
        country: country,
        identity_document: identityDocument,
        number_documents: numberDocument,
      })
    );

    setMessages([
      {
        title: "Изменения прошли успешно!",
        message: "",
        firstColor: "#000",
        secondColor: "#000",
      },
    ]);

    setNdflStatus('pending');
    dispatch(ndflUpdate({status: 'pending'}));

    openModal(scrollWidth);
  };

  const checkNameError = (value, setError) => {
    if (value.length < 2 || value.length > 30) {
      setError(true);
      return;
    }

    setError(false);
  };

  const checkInnError = (value) => {
    const currentValue = value || inn;

    if (currentValue.length < 10 || currentValue.length > 12) {
      setInnError(true);
      return;
    }

    setInnError(false);
  };

  const handleCountryUpdate = (identityDocumentId) => {
    if (identityDocumentId === 0 || identityDocumentId === 1) {
      setCountry(countryList.find((country) => country.id === 0));
    } else {
      setCountry(undefined);
    }
  };

  const renderErrorName = (value) => (
    value.length < 2
      ? <b className="ndfl-form-kz__warning">Мало символов</b>
      : value.length > 30
      ? <b className="ndfl-form-kz__warning">Много символов</b>
      : <br/>
  );

  const renderErrorInn = (value) => (
    value.length < 10
      ? <b className="ndfl-form-kz__warning">Мало символов</b>
      : value.length > 12
        ? <b className="ndfl-form-kz__warning">Много символов</b>
        : <br/>
  );

  const renderErrorPassport = (value) => (
    !value
      ? <b className="ndfl-form-kz__warning">Поле не заполнено</b>
      : ''
  );

  useEffect(() => {
    return () => {
      window.close();
    }
  }, [window]);

  useEffect(() => {
    if (identityDocument) {
      if (identityDocument.id === 0 || identityDocument.id === 1) {
        setCountry(countryList.find((country) => country.id === 0));
      } else {
        setCountry(undefined);
      }
    }
  }, [identityDocument]);

  const renderInputField = (title, placeholder, value, onChange, error, renderError, typeKeyboard = 'text') => {
    return (
      <div className="ndfl-form-kz__field_wrapper">
        <b className="ndfl-form-kz__field-header">{title}</b>
        <input
          value={value}
          type={typeKeyboard}
          readOnly={ndflStatus}
          placeholder={placeholder}
          className={`input input__ndfl ${error ? 'input_warning' : ''} ${ndflStatus ? 'ndfl-form-kz__input_readonly' : ''}`}
          onChange={onChange}
        />
        {renderError && renderError(value)}
      </div>
    );
  };

  const renderDescription = () => {
    return (
      <div className="ndfl-form-kz__description">
        Нажимая кнопку «Отправить», я подтверждаю,
        <div>что я ознакомлен и согласен с условиями</div>
        <a href="/rules.pdf" target={'_blank'} className="input__ndfl-link">
          политики обработки персональных данных
        </a>{" "}
        .
      </div>
    );
  };

  const renderPhotoDescription = () => {
    return (
      <div className="ndfl-form-kz__photo_description">
        Допустимый формат фото паспорта, для прикрепления:{" "}
        <span style={{ color: "#000" }}>
          jpg, png, pdf
          <br/>
        </span>{" "}
        максимальный размер не более{" "}
        <span style={{ color: "#000" }}>10 Мб.</span>
      </div>
    );
  };

  const renderPhotoLoad = (title, id) => {
    const value = files[id];
    if (value) {
      return (
        <div className={`ndfl-form-kz__photo-load ${ndflStatus ? "reset-cursor" : ''}`}>
          <div className={`ndfl-form-kz__photo_loaded ${ndflStatus ? "ndfl-form-kz__photo-loaded" : ''}`}>
            <div className={`ndfl-form-kz__photo-loaded_wrapper ${ndflStatus ? 'status-true' : ''}`}>
              <div className="ndfl-form-kz__background"></div>
              <img className="ndfl-form-kz__photo-loaded_image" src={value}/>
              {!ndflStatus ? (
                <Trash
                  className="trash ndfl-form-kz__trash"
                  onClick={() => setFiles({...files, [id]: null})}
                />
              ) : null}
            </div>
          </div>
        </div>
      );
    }

    return (
      <label htmlFor={id} className={`ndfl-form-kz__photo-load ${ndflStatus ? 'reset-cursor' : ''}`}>
        <input
          type={ndflStatus ? 'text' : 'file'}
          readOnly={ndflStatus}
          hidden
          id={id}
          accept="image/png, image/gif, image/jpeg"
          onChange={(e) => handleFilesChange(e, id)}
        />
        <div className={`ndfl-form-kz__photo_load ${ndflStatus ? 'ndfl-status__photo_load-disable' : ''}`}>
          <img
            alt="img"
            src={require("../../assets/icons/file.png")}
            className="ndfl-form-kz__load_icon"
          />
          {title}
        </div>
      </label>
    );
  };

  const renderPhoto = () => {
    const loadedFiles = files.file1 && files.file2
    return (
      <div className="ndfl-form-kz__field-photo_wrapper">
        <b className="ndfl-form-kz__field-header">Фото документа</b>
        <div className={`ndfl-form-kz__photo-container ${loadedFiles ? 'ndfl-form-kz__photo-container_row' : ''}`}>
          {renderPhotoLoad(
            "Лицевая сторона удостоверения личности",
            "file1"
          )}
          {renderPhotoLoad(
            "Оборотная сторона удостоверения личности",
            "file2"
          )}
        </div>
      </div>
    );
  };

  const renderSend = () => {
    return (
      <Button
        customClass="button_save"
        onClick={handleClickSend}
        title="Отправить"
      />
    );
  };

  if (isMobile) {
    return (
      <>
        <LeavingModal wherePromptVisible={wherePromptVisible}/>
        {ndflStatus ? <NDFLStatus status={ndflStatus} setNdflStatus={setNdflStatus}/> : null}
        <div className="ndfl-form-kz__container">
          {renderInputField(
            "Фамилия",
            "",
            last_name,
            (t) => {
              setLastName(t.target.value);
              checkNameError(t.target.value, setLastNameError);
            },
            lastNameError,
            renderErrorName
          )}
          {renderInputField(
            "Имя",
            "",
            first_name,
            (t) => {
              setfirst_name(t.target.value);
              checkNameError(t.target.value, setFirstNameError);
            },
            firstNameError,
            renderErrorName
          )}
          {renderInputField(
            "Отчество",
            "",
            patronymic,
            (t) => {
              setMiddleName(t.target.value);
              checkNameError(t.target.value, setPatronymicError);
            },
            patronymicError,
            renderErrorName
          )}
          {renderInputField(
            'ИИН',
            "NNNNXXXXXXCC",
            inn,
            (t) => {
              setInn(t.target.value);
              checkInnError(t.target.value);
            },
            innError,
            renderErrorInn,
            'number'
          )}
          <Select
            title={'Документ, удостоверяющий личность'}
            placeholder={'Выбрать документ'}
            visible={visibleDropDownIdentityDocument}
            setVisible={setVisibleDropDownIdentityDocument}
            list={identityDocumentList}
            value={identityDocument}
            setValue={setIdentityDocument}
            ndflStatus={ndflStatus}
          />
          {identityDocument ? renderInputField(
            "Номер документа",
            "123456789",
            numberDocument,
            (t) => {
              setNumberDocument(t.target.value);
            },
            false,
            () => !numberDocument && identityDocument ? renderErrorPassport(numberDocument) : <br />,
            'number'
          ) : null}
          {renderPhoto()}
          {renderPhotoDescription()}
          <Select
            title={'Гражданство'}
            placeholder={'Выбрать страну'}
            visible={visibleDropDownCountry}
            setVisible={setVisibleDropDownCountry}
            list={countryList}
            value={country}
            setValue={setCountry}
            ndflStatus={ndflStatus}
            isDisabled={identityDocument && (identityDocument.id === 0 || identityDocument.id === 1)}
          />
          {!ndflStatus ? renderDescription() : null}
          {!ndflStatus ? renderSend() : null}
          <ErrorModal messages={messages} setMessages={setMessages} />
        </div>
      </>
    );
  }

  return (
    <div>
      {allFieldAreField ? (
        <p className={'ndfl-form-kz__warning_text'}>Все поля – обязательны для заполнения!</p>
      ) : null}
      <LeavingModal wherePromptVisible={wherePromptVisible}/>
      {ndflStatus ? <NDFLStatus status={ndflStatus} setNdflStatus={setNdflStatus}/> : null}
      <div className="ndfl-form-kz__container">
        <div className={'ndfl-form-kz__fio-container'}>
          {renderInputField(
            "Фамилия",
            "",
            last_name,
            (t) => {
              setLastName(t.target.value);
              checkNameError(t.target.value, setLastNameError);
            },
            lastNameError,
            renderErrorName
          )}
          {renderInputField(
            "Имя",
            "",
            first_name,
            (t) => {
              setfirst_name(t.target.value);
              checkNameError(t.target.value, setFirstNameError);
            },
            firstNameError,
            renderErrorName
          )}
          {renderInputField(
            "Отчество",
            "",
            patronymic,
            (t) => {
              setMiddleName(t.target.value);
              checkNameError(t.target.value, setPatronymicError);
            },
            patronymicError,
            renderErrorName
          )}
        </div>
        <div>
          {renderInputField(
            "ИИН",
            "NNNNXXXXXXCC",
            inn,
            (t) => {
              setInn(t.target.value);
              checkInnError(t.target.value);
            },
            innError,
            renderErrorInn,
            'number'
          )}
          <Select
            title={'Документ, удостоверяющий личность'}
            placeholder={'Выбрать документ'}
            visible={visibleDropDownIdentityDocument}
            setVisible={setVisibleDropDownIdentityDocument}
            list={identityDocumentList}
            value={identityDocument}
            setValue={setIdentityDocument}
            ndflStatus={ndflStatus}
          />
          {identityDocument ? renderInputField(
            "Номер документа",
            "123456789",
            numberDocument,
            (t) => {
              setNumberDocument(t.target.value);
            },
            false,
            () => !numberDocument && identityDocument ? renderErrorPassport(numberDocument) : <br />,
            'number'
          ) : null}
          {renderPhoto()}
          {renderPhotoDescription()}
          <Select
            title={'Гражданство'}
            placeholder={'Выбрать страну'}
            visible={visibleDropDownCountry}
            setVisible={setVisibleDropDownCountry}
            list={countryList}
            value={country}
            setValue={setCountry}
            ndflStatus={ndflStatus}
            isDisabled={identityDocument && (identityDocument.id === 0 || identityDocument.id === 1)}
          />
          {!ndflStatus ? renderDescription() : null}
          {!ndflStatus ? renderSend() : null}
        </div>

        <ErrorModal messages={messages} setMessages={setMessages} />
      </div>
    </div>
  );
};

export default NDFLForm;
