import { useDispatch, useSelector } from "react-redux";
import React, {useEffect, useMemo, useRef, useState} from "react";
import { useMediaQuery } from "react-responsive";
import {DateRange} from "react-date-range";
import * as dateFnsLocales from "date-fns/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import { addEpsOrders, addPayments, getEPSOrders, getPaymentsOrders, resetOrders } from "../../redux/reducers/orderListReducer";
import { formatCreatedDate, getDates, parseDate } from "../../utils/helpers";
import OrderItem from "./components/OrderItem";

import "./order_list.css";

const OrderList = () => {
  const calendarRef = useRef(null);
  const dispatch = useDispatch();

  const orders = useSelector((state) => state.orders);
  const listLang = useSelector((state) => state.listLang);
  const orderList = useSelector((state) => state.ordersList);

  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [orderType, setOrderType] = useState("all");
  const [showTypes, setShowTypes] = useState(false);
  const [showPeriods, setShowPeriods] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [page, setPage] = useState(1);
  const [orderPeriod, setOrderPeriod] = useState("month");
  const [visibleShowMore, setVisibleShowMore] = useState(false);
  const [isShowMore, setIsShowMore] = useState(false);
  const [date, setDate] = useState({
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
    endDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
    key: 'selection',
  });
  const totalCount = orderList.payments.count + orderList.eps.count;
  const paymentsAndPrizesOrders = useMemo(() => {
    return [...orderList.payments.orders, ...orderList.eps.orders]
  }, [orderList.payments.orders, orderList.eps.orders]);
  
  const [customDate, setCustomDate] = useState({
    startDate: formatCreatedDate(date.startDate, 'date'),
    endDate: formatCreatedDate(date.endDate, 'date', true, 'custom'),
  });

  const datesForMonth = getDates('month');
  const datesForThreeMonths = getDates('threeMonths');

  const getDatePeriod = (period) => {
    switch (period) {
      case 'month':
        return datesForMonth;
      case 'months':
        return datesForThreeMonths;
      case 'custom':
        return customDate;
      default:
        return datesForMonth;
    }
  };

  const period = getDatePeriod(orderPeriod);

  const orderTypeList = {
    all: "все",
    card: "на карту",
    phone: "на телефон",
    sbp: "СБП",
    prize: "заказы ЭПС",
  };

  const orderTypeLabels = {
    card: "Банковская карта",
    phone: "Мобильная связь",
    sbp: "СБП",
    prize: "ЭПС",
  };

  const orderPeriodList = {
    month: "за месяц",
    months: "за 3 месяца",
    custom: "выбрать",
  };

  const itemType = (name, type) => {
    switch(type) {
      case 'card': 
        return `XXXX XXXX XXXX ${name.slice(-4)}`;
      case 'phone':
        return  `+7 XXX XXX-${name.slice(-5)}`;
      case 'sbp':
        return  `+7 XXX XXX-${name.slice(-5)}`;
      default :
        return name
    }
  };

  const filteredList = () => {
    let result;
  
    if (orderType !== 'all') {
      result = paymentsAndPrizesOrders
        .filter(item => item.payment_type === orderType);
    } else {
      result = paymentsAndPrizesOrders;
    }

    result.sort((a, b) => parseDate(b.created_at) - parseDate(a.created_at));
    
    return result.map(orderItem => renderOrder(orderItem, orderItem.order_id));
  };

  useEffect(() => {
    if (showCalendar) {
      const handleClick = (e) => {
        if (!calendarRef.current) return;
        if (!calendarRef.current.contains(e.target)) {
          setShowCalendar(false);
        }
      }

      document.addEventListener('click', handleClick);

      return () => {
        document.removeEventListener('click', handleClick);
      }
    }
  }, [showCalendar]);

  useEffect(() => {
    if (totalCount >= page * 30) {
      setVisibleShowMore(true);
    } else {
      setVisibleShowMore(false);
    }
  }, [totalCount, page]);

  useEffect(() => {
    if (orderPeriod === 'custom' && date.startDate && date.endDate) {
      const customDate = {
        startDate: formatCreatedDate(date.startDate, 'date'),
        endDate: formatCreatedDate(date.endDate, 'date', true, 'custom')
      };
      setCustomDate(customDate);
      setIsShowMore(false)
    }
  }, [date.startDate, date.endDate, orderPeriod]);
  
  useEffect(() => {
    const orders = {
      page_number: 1,
      per_page: 15,
      date_before: period.endDate,
      date_after: period.startDate
    };
    if (paymentsAndPrizesOrders.length === 0 || !isShowMore) {
      if(!isShowMore) {
        setIsShowMore(true);
        dispatch(getPaymentsOrders(orders)).then(res => {
          if (res.payload.success) {
            dispatch(addPayments({
              ...res.payload,
              replaceExisting: true
            }));
          }
        });
        dispatch(getEPSOrders(orders)).then(res => {
          if (res.payload.success) {
            dispatch(addEpsOrders({
              ...res.payload,
              replaceExisting: true
            }));
          }
        })
      }
    }
    
  }, [dispatch, paymentsAndPrizesOrders, isShowMore,  period.startDate, period.endDate])


  const handleClickItem = (type, item) => {
    if (type === 'type') {
      setOrderType(item.type);
    }
    if (type === 'period') {
      setOrderPeriod(item.period);

      if (item.period !== orderPeriod) {
        setPage(1);
        setIsShowMore(false);
        dispatch(resetOrders());
      }

      if (item.period === "custom") {
        setShowCalendar(true);

        if (item.mobile) {
          document.body.classList.add('modal-open');
        }
      } 
    }
    
    setShowTypes(false);
    setShowPeriods(false);
    document.body.classList.remove('modal-open');
  };

  const handleClickShowMore = () => {
    const lastItem = paymentsAndPrizesOrders[paymentsAndPrizesOrders.length - 1];
    const lastItemDate = parseDate(lastItem.created_at);
    const startDate = parseDate(period.startDate);

    if (!isNaN(lastItemDate) && lastItemDate >= startDate) {
      setPage(prevPage => prevPage + 1);
      setIsShowMore(true);
      const allOrders = {
        page_number: page + 1,
        per_page: 15,
        date_before: period.endDate,
        date_after: period.startDate
      };

      dispatch(getPaymentsOrders(allOrders)).then(res => {
        if (res.payload.success) {
          const filteredPayments = res.payload.data.filter(payment => {
            if (!payment || !payment.created_at) return false;
            const paymentDate = parseDate(payment.created_at);
  
            return paymentDate >= startDate;
          });

          if (filteredPayments.length > 0) {
            dispatch(addPayments({
              ...res.payload,
              count: res.payload.count,
              data: filteredPayments,
              replaceExisting: false
            }));
          }
        }
      })
      dispatch(getEPSOrders(allOrders)).then(res => {
        if (res.payload.success) {
          const filteredOrders = res.payload.data.orders.filter(order => {
            if (!order || !order.created_at) return false;
            const orderDate = parseDate(order.created_at);
  
            return orderDate >= startDate;
          });
          if (filteredOrders.length > 0) {
            dispatch(addEpsOrders({
              ...res.payload,
              count: res.payload.data.count,
              data: filteredOrders,
              replaceExisting: false
            }));
          }
        }
      })
    }
  };

  const handleClickOpenItemModal = (type) => {
    if (type === 'type') {
      setShowTypes(true);
    } else {
      setShowPeriods(true);
    }
    document.body.classList.add('modal-open');
  };

  const renderTypeItem = (item) => {
    const isSelected = item.type === orderType;
    return (
      <p
        className={`order-list__type-item ${isSelected ? 'order-list__type-item_active' : ''}`}
        onClick={() => handleClickItem('type', item)}>
        {item.name}
      </p>
    );
  };

  const renderPeriodItem = (item) => {
    const isSelected = item.period === orderPeriod;
    return (
      <p
        className={`order-list__type-item ${isSelected ? 'order-list__type-item_active' : ''}`}
        onClick={() => handleClickItem('period', item)}>
        {item.name}
      </p>
    );
  };

  const renderTypeModal = () => {
    return (
      <div className="order-list__modal-container">
        <div className="order-list__modal-content">
          <span className="order-list__modal-title">Тип вывода:</span>
          {renderTypeItem({ name: "все", type: "all" })}
          {renderTypeItem({ name: "на карту", type: "card" })}
          {renderTypeItem({ name: "на телефон", type: "phone" })}
          {renderTypeItem({ name: "СБП", type: "sbp" })}
          {renderTypeItem({ name: "заказы ЭПС", type: "prize", mobile: true})}
        </div>
      </div>
    );
  };

  const renderPeriodModal = () => {
    return (
      <div className="order-list__modal-container">
        <div className="order-list__modal-content">
          <span className="order-list__modal-title">Период истории:</span>
          {renderPeriodItem({ name: "за месяц", period: "month" })}
          {renderPeriodItem({ name: "за 3 месяца", period: "months" })}
          {renderPeriodItem({ name: "выбрать", period: "custom" })}
        </div>
      </div>
    );
  };

  const renderShowMore = () => {
    return (
      <div className="order-list__show-more" onClick={handleClickShowMore}>
        <p>Показать еще</p>
        <img
          alt="img"
          src={require("../../assets/icons/refresh.png")}
          className="order-list__show-more_icon"
        />
      </div>
    );
  };

  const renderTopMenu = () => {
    if (isMobile) {
      return (
        <div className="order-list__menu-container">
          <div>
            <span className="order-list__menu-title">Тип вывода:</span>
            <div
              className="order-list__menu-list"
              onClick={() => handleClickOpenItemModal('type')}>
              <span className="order-list__menu-text_mobile">
                {orderTypeList[orderType]}
              </span>
              <img
                alt="img"
                src={require("../../assets/icons/arrow_black.png")}
                className="order-list__menu-icon_mobile"
              />
            </div>
          </div>

          <div>
            <span className="order-list__menu-title">Период истории:</span>
            <div
              className="order-list__menu-list"
              onClick={() => handleClickOpenItemModal('period')}>
              <span className="order-list__menu-text_mobile">
                {orderPeriodList[orderPeriod]}
              </span>
              <img
                alt="img"
                src={require("../../assets/icons/arrow_black.png")}
                className="order-list__menu-icon_mobile"
              />
            </div>
            {showCalendar && renderCalendarModal()}
          </div>
        </div>
      );
    }
    return (
      <div className="order-list__menu-container">
        <div className="order-list__menu-list">
          <p className="order-list__menu-title">Тип вывода:</p>
          {renderTypeItem({ name: "все", type: "all" })}
          {renderTypeItem({ name: "на карту", type: "card" })}
          {renderTypeItem({ name: "на телефон", type: "phone" })}
          {renderTypeItem({ name: "СБП", type: "sbp" })}
          {renderTypeItem({ name: "заказы ЭПС", type: "prize" })}
        </div>
        <div className="order-list__menu-list order_top_menu_list_period" ref={calendarRef}>
          <p className="order-list__menu-title">Период истории:</p>
          {renderPeriodItem({ name: "за месяц", period: "month" })}
          {renderPeriodItem({ name: "за 3 месяца", period: "months" })}
          {renderPeriodItem({ name: "выбрать", period: "custom" })}
          {showCalendar && renderCalendarModal()}
        </div>
      </div>
    );
  };

  const renderOrder = (item, index) => {
    const price = item.amount_withdrawn_participant / 100 || item.requested_amount / 100 || item.order_sum / 100;

    if (isMobile) {
      return (
        <div className="order-list__order-container" key={index}>
          <b className="order-list__order-header_number">
            Заказ №{item.order_id} от {item.created_at}{" "}
          </b>
          <div className="order-list__order-header-title">
            Сумма заказа:{" "}
            <b className="order-list__order-total-price">
              {price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' ' + listLang.symbol}
            </b>
          </div>
          <div className="order-list__item-wrapper"></div>
          <div className="order-list__order-header-title">Состав заказа:</div>
          <OrderItem
            item={item}
            type={item.payment_type}
            id={item.order_id}
            isMobile={isMobile}
            listLang={listLang}
            orderTypeLabels={orderTypeLabels}
          />
        </div>
      );
    }
    return (
      <div className="order-list__order-container" key={index}>
        <b className="order-list__order-header_number">
          Заказ №{item.order_id} от {item.created_at}{" "}
        </b>
        <div className="order-list__order-header-title">
          Сумма заказа:{" "}
          <b className="order-list__order-total-price">
            {price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' ' + listLang.symbol}
          </b>
        </div>
        <div className="order-list__item-wrapper">
          <span className="order-list__order-item-title">Состав заказа</span>
          <span className="order-list__order-item_status-title">Статус заказа</span>
        </div>
        <OrderItem
          item={item}
          type={item.payment_type}
          id={item.order_id}
          isMobile={isMobile}
          listLang={listLang}
          orderTypeLabels={orderTypeLabels}
        />
      </div>
    );
  };

  const renderCalendarModal = () => {
    if(isMobile) {
      return (
        <div className="order-list__calendar-container">
          <div className={'order-list__calendar-wrapper'}>
            <div className="order-list__calendar-content">
              <span className="order-list__calendar-title">Выберите период</span>
              <img
                alt="img"
                onClick={() => {
                  setShowCalendar(false);
                  document.body.classList.remove('modal-open');
                }}
                src={require("../../assets/icons/close.png")}
                className="order-list__calendar-icon"
              />
              <DateRange
                className={`order-list__calendar-months`}
                initMonths={0}
                ranges={[date]}
                onChange={item => {
                  setDate(item.selection);
                }}
                locale={dateFnsLocales["ru"]}
                months={1}
                maxDate={new Date()}
                color="#1E72EC"
                rangeColors="#1E72EC"
              />
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className="order-list__calendar-container">
        <div className={'order-list__calendar-wrapper'}>
          <div className="order-list__calendar-content">
            <span className="order-list__calendar-title">Выберите период</span>
            <img
              alt="img"
              onClick={() => setShowCalendar(false)}
              src={require("../../assets/icons/close.png")}
              className="order-list__calendar-icon"
            />
          </div>
          <DateRange
            initMonths={0}
            ranges={[date]}
            onChange={item => {
              if (item.selection.startDate !== date.startDate || item.selection.endDate !== date.endDate) {
                setDate(item.selection);
              }
            }}
            locale={dateFnsLocales["ru"]}
            months={1}
            maxDate={new Date()}
            color="#1E72EC"
            rangeColors="#1E72EC"
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      {renderTopMenu()}
      {showTypes && renderTypeModal()}
      {showPeriods && renderPeriodModal()}
      <div className="order-list__list-item-container">
        {filteredList()}
      </div>
      {visibleShowMore && renderShowMore()}
    </div>
  );
};

export default OrderList;
