import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import FlatList from "flatlist-react";
import useDropdownMenu from "react-accessible-dropdown-menu-hook";
import { useMediaQuery } from "react-responsive";

import Button from "../Button/Button";
import ShowMore from "../ShowMore/ShowMore";
import Prize from "./components/Prize";
import Filters from "./components/Filters";
import { getStringDeclination } from "../../utils/helpers";

import "./prize_list.css";

const Prizes = () => {
  const categoryRef = useRef(null);
  const isBigTablet = useMediaQuery({ minWidth: 1024, maxWidth: 1400 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const prize_list = useSelector((state) => state.prizes.items);
  const settings = useSelector((state) => state.settings.categories);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [showMenu, setShowMenu] = useState(false);
  const [sort, setSort] = useState("all");
  const [visibleCategory, setVisibleCategory] = useState(false);
  const [visibleShowMore, setVisibleShowMore] = useState(false);

  let categories = settings
  .map((item) => ({category: item.id, name: item.name}))
  .sort((a,b) => a.name.localeCompare(b.name))
  .filter((obj, idx, arr) => idx === arr.findIndex((t) => t.name === obj.name));

  const [selectedCategories, setSelectedCategories] = useState([]);
  const cdd = useDropdownMenu(categories.length);

  let count = "22%";
  let gap = "32px";

  if (isMobile) {
    count = "47%";
    gap = "16px";
  }
  if (isTablet) {
    count = "47%";
    gap = "20px";
  }
  if (isBigTablet) {
    count = "32%";
    gap = "20px";
  }

  const getItems = () => {
    let items = prize_list;

    items = items.filter((a) =>
      a.name.toLowerCase().includes(searchValue.toLowerCase())
    );

    if (selectedCategories.length > 0) {
      items = items.filter((item) =>
        selectedCategories.includes(item.category.trim())
      );
    }

    if (sort === "unavailable") {
      return items.sort((item) => !item.available);
    }

    items = items.slice(0, page * 24);

    return sort === "all"
      ? items.sort((a, b) => b.id - a.id)
      : items.sort((item) => item.available);
  };

  const updateSelectedCategories = (item) => {
    setPage(1);
    selectedCategories.includes(item.name)
      ? setSelectedCategories(selectedCategories.filter((i) => i !== item.name))
      : setSelectedCategories([...selectedCategories, item.name])
  };

  const handleClickSelectedItem = (item) => {
    if (isMobile && selectedCategories.length > 0) {
      selectedCategories.includes(item.category)
      ? setSelectedCategories(
          selectedCategories.filter((i) => i !== item.category)
        )
      : setSelectedCategories([
          ...selectedCategories,
          item.category,
        ]);

      if (showMenu) {
        updateSelectedCategories(item)
      } 
    } else {
      updateSelectedCategories(item)
    } 
  };

  const handleDeleteSelectedItem = (e) => {
    const currentItem = e.currentTarget.textContent;
    
    e.preventDefault();
    e.stopPropagation();

    setSelectedCategories((prevSelectedCategories) =>
      prevSelectedCategories.includes(currentItem)
        ? prevSelectedCategories.filter((i) => i !== currentItem)
        : prevSelectedCategories
    );
  };

  useEffect(() => {
    let items = prize_list;

    items = items.filter((a) =>
      a.name.toLowerCase().includes(searchValue.toLowerCase())
    );

    if (selectedCategories.length > 0) {
      items = items.filter((item) =>
        selectedCategories.includes(item.category.trim())
      );
    }

    if (sort === "unavailable") {
      return items.sort((item) => !item.available);
    }

    if (items.length > page * 24) {
      setVisibleShowMore(true);
    } else {
      setVisibleShowMore(false);
    }
  }, [prize_list, selectedCategories, page]);

  useEffect(() => {
    if (!visibleCategory) return;

    const handleClick = (e) => {
      if (!categoryRef.current) return;
      if (!categoryRef.current.contains(e.target)) {
	      setVisibleCategory(false);
      }
    }

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    }
  }, [visibleCategory]);

  const handleShowMore = () => {
    setPage(page + 1);
  };

  if (showMenu) {
    return (
      <div className="prizes-list__category-menu">
        <div className="prizes-list__clear-menu">
          <p
            onClick={() => {
              setSort("all");
              setSelectedCategories([]);
            }}
            className="prizes-list__clear-button">
            Очистить
          </p>
          <p className="prizes-list__filter-title">Фильтры</p>
          <p onClick={() => setShowMenu(false)} className="prizes-list__menu-button_close">
            <img
              alt="img"
              src={require("../../assets/icons/close.png")}
              className="prizes-list__filter-icon_close"
            />
          </p>
        </div>

        <p className="prizes-list__category-title">Категории</p>
        <div className="prizes-list__category-container_all">
          <button
            onClick={() => setSelectedCategories([])}
            className={`
              prizes-list__item-button 
              ${selectedCategories.length === 0 ? 'prizes-list__item-button_active' : ''}
            `}>
            Все
          </button>
          {categories.map((item) => (
            <button
              onClick={() => handleClickSelectedItem(item)}
              className={`
                prizes-list__item-button 
                ${selectedCategories.includes(item.name) ? 'prizes-list__item-button_active' : ''}
              `}>
              {item.name}
            </button>
          ))}
        </div>

        <Button
          onClick={() => setShowMenu(false)}
          customClass="button_blue button_bottom"
          title={"ПОКАЗАТЬ " + getItems().length + " БРЕНДОВ"}
        />
      </div>
    );
  }

  return (
    <div className={'prizes-list__main-container'}>
      <p className="prizes-list__length">В каталоге - {getStringDeclination(prize_list.length, 'бренд')}</p>
      <Filters
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        categories={categories}
        isMobile={isMobile}
        setShowMenu={setShowMenu}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        clickSelectedItem={handleClickSelectedItem}
      />
      {isMobile && selectedCategories.length > 0 && (
        <div className="prizes-list__selected-list">
          {categories
            .filter((item) => selectedCategories.includes(item.name))
            .map((item) => (
              <button
                onClick={(e) => handleDeleteSelectedItem(e)}
                className="prizes-list__selected-item">
                {item.name}
                <img
                  alt="img"
                  src={require("../../assets/icons/close.png")}
                  className="prize-list__selected-item_close-icon"
                />
              </button>
            ))}
        </div>
      )}
      <div>
        <FlatList
          key={sort.toString()}
          list={getItems()}
          renderItem={<Prize />}
          renderWhenEmpty={() => <div>Список пуст</div>}
          displayGrid
          gridGap={gap}
          minColumnWidth={count}
        />
      </div>
      {visibleShowMore ? (
        <ShowMore title={'Показать еще'} handleOnClick={handleShowMore} />
      ) : null}
    </div>
  );
};

export default Prizes;
