import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ax from "../../utils/ax";

const initialState = {
  payments: {
    orders: [],
    count: 0
  },
  eps: {
    orders: [],
    count: 0
  }
};

export const getPaymentsOrders = createAsyncThunk(
  "payment/list",
  async (dataParams) => {
    try {
      const {data} = await ax().get('payment/list', {params: dataParams})
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const getEPSOrders = createAsyncThunk(
  "cards/get-orders",
  async (dataParams) => {
    try {
      const {data} = await ax().get('cards/get-orders', {params: dataParams})
      return data;
    } catch (error) {
      return error;
    }
  }
);

const ordersListSlice = createSlice({
  name: 'ordersList',
  initialState,
  reducers: {
    addPayments(state, action) {
      if (!action.payload || !action.payload.data) {
        return state; 
      }

      if (action.payload.replaceExisting) {
        state.payments.orders = action.payload.data;
      } else {
        const newPayments = action.payload.data;
        const paymentToAdd = newPayments.filter(newPayment => 
          !state.payments.orders.some(existingPayment => 
            existingPayment.order_id === newPayment.order_id
          )
        )

        state.payments.orders = [...state.payments.orders, ...paymentToAdd];
      }
      state.payments.count = action.payload.count;
    },
    addEpsOrders(state, action) {
      if (!action.payload || !action.payload.data) {
        return state; 
      }

      if (action.payload.replaceExisting) {
        state.eps.orders = action.payload.data.orders;
        state.eps.orders.map(item => item.payment_type = 'prize');
      } else {
        const newEps = action.payload.data;
        const epsToAdd = newEps
        .filter(newPayment => 
          !state.eps.orders.some(existingPayment => 
            existingPayment.order_id === newPayment.order_id
          )
        );
        epsToAdd.map(item => item.payment_type = 'prize')

        state.eps.orders = [...state.eps.orders, ...epsToAdd];
      }

      state.eps.count = action.payload.data.count || action.payload.count;
    },
    resetOrders(state, action) {
      state.payments.orders = [];
      state.payments.count = [];
      
        state.eps.orders = [];
        state.eps.count = [];
      
    }
  }
})

export const { addPayments, addEpsOrders, resetOrders } = ordersListSlice.actions

export default ordersListSlice.reducer
