import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ax from "../../utils/ax";

const initialState = {
  categories: [],
  payment_settings: [],
  type_points_received: []
};

export const getSettings = createAsyncThunk(
  "settings/get",
  async () => {
    try {
      const {data} = await ax().get('settings/get', {})
      return data;
    } catch (error) {
      return error;
    }
  }
);

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setCategories(state, action) {
      const { settings } = action.payload;
      state.categories = settings.categories;
      state.payment_settings = settings.payment_settings;
      state.type_points_received = settings.type_points_received;
    },
  },
});

export const { setCategories } = settingsSlice.actions;

export default settingsSlice.reducer;
