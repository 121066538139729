import React from "react";

import GoBack from "../GoBack/GoBack";
import { closeModal } from "../../utils/helpers";

import "./RulesModal.css";

const RulesModal = ({setRules, item, type}) => {
  const handleResetModal = () => {
    setRules(false);
    closeModal();
  };

  return (
    <div className="rules-modal">
      <div className="rules-modal__content">
        <img
          onClick={handleResetModal}
          alt="img"
          src={require("../../assets/icons/close.png")}
          className="rules-modal__icon"
        />
        <h3 className={'rules-modal__title'}>
          {type === 'terms_of_use' ? (
            <> Правила использования<br/>подарочного сертификата </>
          ) : (
            <> Ограничения на <br/> использование сертификата </>
          )}
        </h3>
        <p className={'rules-modal__rules'}>{item.replace(/[^А-Яа-яA-Za-z\w\s().,]|_/g, ' ')}</p>
        <GoBack />
      </div>
    </div>
  );
};

export default RulesModal;