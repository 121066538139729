import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import ax from "../../utils/ax";
import { stateUpdate } from "../utils";

const initialState = {
  commission_user: 0,
  tax_user: 0,
  sum_user: 0
};

export const getTotalSum = createAsyncThunk(
  "payment/calculation",
  async (paymentData) => {
    try {
      const {data} = await ax().get('payment/calculation', {params: paymentData})
      return data;
    } catch (error) {
      return error;
    }
  }
);

const calculationSlice = createSlice({
  name: "calculation",
  initialState,
  reducers: {
    setPayments(state, action) {
      stateUpdate(state, action.payload)
    },
  },
});

export const { setPayments } = calculationSlice.actions;

export default calculationSlice.reducer;
