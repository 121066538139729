import React, { memo } from "react";

const Description = memo(({item, description, setDescription}) => {
  const handleShowMore = (text, fullText, setTypeText) => {
    if (text.length > 73) {
      setTypeText(`${fullText.slice(0, 70)}...`);
    } else {
      setTypeText(fullText);
    }
  };

  return (
    <div className="prize__description-container">
      <p className="prize__description-wrapper">
        <span className={'prize__description-text'} id={'description'}>{description}</span>
        <br />
        <br />
        <a 
          className="prize__description-link" 
          onClick={() => handleShowMore(description, item.description, setDescription)}>
          {description.length > 73 ? 'Скрыть' : 'Подробнее'}
        </a>
      </p>
    </div>
  );
});

Description.displayName = 'Description';

export default Description;