export default {
  symbol: 'Br',
  countrySymbol: 'BY',
  rusCountrySymbol: 'БУ',
  organization: 'Беларуские организации',
  fullCountryName: 'Республики Беларусь',
  taxSystem: 'ПНФЛ',
  individualNumberText: 'Личный номер',
  maxPaymentBankText: <>500&nbsp;</>,
  maxPaymentPhoneText: <>5&nbsp;000&nbsp;</>,
  maxPaymentBankValue: 500,
  maxPaymentPhoneValue: 5000,
}