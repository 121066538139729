import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import TopMenu from "../components/TopMenu/TopMenu";
import Header from "../components/Header/Header";
import NDFLForm from "../components/NDFLForm/NDFLForm";
import NDFLFormKZ from "../components/NDFLFormKZ/NDFLFormKZ";
import NDFLFormBY from "../components/NDFLFormBY/NDFLFormBY";

const NDFLScreen = () => {
  const [ndflForm, setNdflForm] = useState(<NDFLForm />);

  const lang = useSelector((state) => state.user.lang);
  const ndfl = useSelector((state) => state.ndfl);

  useEffect(() => {
    if (lang === 'ru') {
      setNdflForm(<NDFLForm />);
    } else if (lang === 'kz') {
      setNdflForm(<NDFLFormKZ />);
    } else if (lang === 'by') {
      setNdflForm(<NDFLFormBY />)
    }
  }, [lang]);

  return (
    <div>
      <Header />
      <TopMenu />
      {ndflForm}
    </div>
  );
};

export default NDFLScreen;
