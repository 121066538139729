export default function renderPopUpText(lang) {
	switch (lang) {
		case "kz": {
			return (
				<>
					<p className={"ndfl-modal__rules"}>
            ИПН — это налог на доходы физических лиц. Взимается не только с заработной платы, но и с доходов, полученных через программы лояльности и других источников в денежном или натуральном виде. Зачем его платить? ИПН нужен для финансирования различных государственных нужд — медицины, образования, обороны и других сфер. Поэтому, уплачивая ИПН, вы помогаете обществу и участвуете в формировании бюджета страны.
					</p>
				</>
			);
		}
		case "by": {
			return (
				<>
					<p className={"ndfl-modal__rules"}>
            ПНФЛ — это налог на доходы физических лиц. Взимается не только с заработной платы, но и с доходов, полученных через программы лояльности и других источников в денежном или натуральном виде. Зачем его платить? ПНФЛ нужен для финансирования различных государственных нужд — медицины, образования, обороны и других сфер. Поэтому, уплачивая ПНФЛ, вы помогаете обществу и участвуете в формировании бюджета страны.
					</p>
				</>
			);
		}
		default:
			return (
				<p className={"ndfl-modal__rules"}>
          НДФЛ — это налог на доходы физических лиц. Взимается не только с заработной платы, но и с доходов, полученных через программы лояльности и других источников в денежном или натуральном виде. Зачем его платить? НДФЛ нужен для финансирования различных государственных нужд — медицины, образования, обороны и других сфер. Поэтому, уплачивая НДФЛ, вы помогаете обществу и участвуете в формировании бюджета страны.
				</p>
			);
	}
}
