import React, { useState, useCallback, useRef } from 'react';

import Button from '../Button/Button';

import { ReactComponent as Refresh } from '../../assets/iconSvg/refresh_foto.svg';
import { ReactComponent as Delete } from '../../assets/iconSvg/trash.svg';
import { ReactComponent as Upload } from '../../assets/iconSvg/upload.svg'

import "./ImageDragAndDrop.css";

const ImageDragAndDrop = ({setIsSelfi, setFiles, files, setPostFiles, postFiles, title, id, error, setPhotoError}) => {
  const [image, setImage] = useState(null);
  const fileInputRef = useRef(null);

  const validateFile = (file) => {
    const allowedFormats = ['image/jpeg', 'image/tiff', 'application/pdf'];
    const maxSizePhoto = 10 * 1024 * 1024; // 10MB
    const minSizeDoc = 10 * 1024; // 10KB

    if (file && !allowedFormats.includes(file.type)) {
      return 'Неверный формат загружаемого изображения. Повторите попытку.';
    }

    if ((file && file.type.startsWith('image/') && file.size > maxSizePhoto) ||
        (file && file.type === 'application/pdf' && file.size < minSizeDoc)) {
      return 'Размер файла превышает максимально/минимально допустимый размер. Повторите попытку.';
    }

    return null;
  };

  const handleFile = useCallback((file) => {
    const validationError = validateFile(file);

    if (validationError) {
      setPhotoError(validationError);
      return;
    }

    setPhotoError(null);

    const reader = new FileReader();
    setPostFiles({...postFiles, [id]: file})
    reader.onload = (e) => {
      setImage(e.target.result);
      setFiles({...files, [id]: reader.result})
    };
    reader.readAsDataURL(file);
  }, [files, id, postFiles, setFiles, setPostFiles, setPhotoError]);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
  }, []);

  const onDrop = useCallback((event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];

    handleFile(file);
    setIsSelfi(true);
  }, [handleFile, setIsSelfi]);

  const onClick = useCallback(() => {
    if (!image) {
      fileInputRef.current?.click();
    }
  }, [image]);

  const onFileInputChange = useCallback((event) => {
    const file = event.target.files[0];

    handleFile(file);
    setIsSelfi(true)
  }, [handleFile, setIsSelfi]);

  const onReupload = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const onDelete = useCallback(() => {
    setImage(null);
    setPhotoError(null);
    setIsSelfi(false);
    setPostFiles({...postFiles, [id]: null});
    setFiles({...files, [id]: null});

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }, [files, id, postFiles, setFiles, setPostFiles, setIsSelfi, setPhotoError]);

  return (
    <div className={`drop-image__wrapper ${image ? 'drop-image__wrapper-loaded ' : ''}`}>
      <div
        onDragOver={onDragOver}
        onDrop={onDrop}
        onClick={onClick}
        className={`
          drop-image__container 
          ${image ? 'reset-cursor drop-image__loaded-photo' : ''}
          ${error ? 'drop-image__error' : ''}
        `}>
        <input 
          type="file"
          ref={fileInputRef}
          onChange={onFileInputChange}
          className='drop-image__input'
          accept="image/jpeg,image/tiff,application/pdf"
        />
        {image ? (
          <img src={image} alt="uploaded" className='drop-image__img' />
        ) : (
          <div className='drop-image__info-container'>
            <Upload />
            <h2 className='drop-image__info-title'>Выберите или перетащите сюда файл.</h2>
            <p className='drop-image__info-description'>
              Доступные форматы: JPEG, TIFF, PDF. 
              <br/>
              •	Размер отправляемого файла с изображением документа — до 10Mb; 
              <br/>
              •	Минимальный размер отправляемого файла документа — 10Кб
            </p>
          </div>
        )}
      </div>
      {image && (
        <div className='drop-image__buttons'>
          <div className='drop-image__button-content'>
            <Refresh />
            <Button
              customClass='drop-image__button'
              onClick={onReupload}
              title='Загрузить снова'
            />
          </div>
          <div className='drop-image__button-content'>
            <Delete />
            <Button
              customClass='drop-image__button'
              onClick={onDelete}
              title='Удалить'
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageDragAndDrop;