export default {
  symbol: '₸',
  countrySymbol: 'KZ',
  rusCountrySymbol: 'КЗ',
  organization: 'Казахстанские организации',
  fullCountryName: 'Республики Казахстан',
  taxSystem: 'ИПН',
  individualNumberText: 'ИИН',
  maxPaymentBankText: <>65&nbsp;000&nbsp;</>,
  maxPaymentPhoneText: <>5&nbsp;000&nbsp;</>,
  maxPaymentBankValue: 65000,
  maxPaymentPhoneValue: 5000,
}