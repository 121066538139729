import React, { memo, useCallback } from "react";

const AddCardOpen = memo(({
  item,
  items,
  taxSettings,
  cPrice, 
  setCPrice, 
  cAmount, 
  setCAmount, 
  listLang, 
  handleClickCard,
  warningInputNominal,
  warningInputQty,
  setWarningNominal,
  setWarningQty,
  setItems
}) => {
  const handleWarningInputNominal = useCallback((propsCPrice) => {
    const currentValue = parseInt(propsCPrice ? propsCPrice : cPrice);

    if ((currentValue && item.step && (currentValue / item.step) % 1 !== 0) || 
      (currentValue < item.min_nominal || currentValue > item.max_nominal)) {
      setWarningNominal(true);
      return;
    }

    setWarningNominal(false);
  },[cPrice, item.step, setWarningNominal, item.min_nominal, item.max_nominal]);

  const handleWarningInputQty = useCallback((propsCAmount) => {
    const currentValue = propsCAmount ? propsCAmount : cAmount;

    if (!currentValue || (currentValue < 1)) {
      setWarningQty(true);
      return;
    }

    setWarningQty(false);
  }, [cAmount, setWarningQty]);

  const handleClickAddNewItem = () => {
    handleWarningInputNominal();
    handleWarningInputQty();

    if (!(cAmount.length > 0 && cPrice.length > 0) || warningInputNominal || warningInputQty) {
      return;
    }

    let iCopy = [...items];

    const findItemIndex = items.findIndex(item => item.price === parseInt(cPrice));

    if (findItemIndex !== -1) {
      iCopy[findItemIndex] = {
        ...iCopy[findItemIndex],
        available: iCopy[findItemIndex].available + parseInt(cAmount),
        amount: iCopy[findItemIndex].amount + parseInt(cAmount),
      }
    } else {
      iCopy.push({
        id: parseInt(cPrice),
        guid: item.guid_1c,
        price: parseInt(cPrice),
        user_markup: +item.user_markup,
        incomeTax: +taxSettings?.user || 0,
        user_discount: +item.user_discount,
        available: 3,
        amount: parseInt(cAmount),
        isNew: true,
      });
    }

    setItems(iCopy);
    setCPrice("");
    setCAmount("");
  };

  const validatePrice = () => {
    if (!cPrice) return item.step;
  
    const currentValue = parseInt(cPrice);
    const validations = [
      { condition: currentValue < item.min_nominal, value: item.min_nominal },
      { condition: currentValue > item.max_nominal, value: item.max_nominal },
      { condition: true, value: Math.round(currentValue / item.step) * item.step }
    ];
  
    return validations.find(v => v.condition).value;
  };

  const getErrorMessage = () => {
    if (!cPrice) return '';
  
    const currentValue = parseInt(cPrice);
    const errors = [
      { 
        condition: currentValue < item.min_nominal, 
        message: `Значение номинала не может быть меньше ${item.min_nominal}` 
      },
      { 
        condition: currentValue > item.max_nominal, 
        message: `Значение номинала не может быть больше ${item.max_nominal}` 
      },
      { 
        condition: currentValue % item.step !== 0, 
        message: `Неправильное значение номинала, ближайшее число ${validatePrice()}` 
      }
    ];
  
    return errors.find(e => e.condition)?.message || '';
  };

  return (
    <div className="prize__new-card-container_open">
      <img
        alt="img"
        src={require(`../../../assets/icons/add_card_${listLang.countrySymbol.toLocaleLowerCase()}.png`)}
        className="prize__new-card_open-img"
        onClick={handleClickCard}
      />
      <div>
        <div>
          <p className="prize__new-card-title">Добавить карту со своим номиналом</p>
          <div className="prize__new-card-period">
            {item.validity_period_nominal}
          </div>
        </div>
        <div className="prize__new-card-content_open">
          <input
            value={cPrice}
            placeholder={"Номинал"}
            onKeyPress={(event) => {
              if (!(/[0-9]/.test(event.key) || event.key === ".")) {
                event.preventDefault();
              }
            }}
            onChange={(t) => {
              setCPrice(t.target.value);
              handleWarningInputNominal(t.target.value);
            }}
            className={`
              prize__new-card-input
              prize__new-card-input_price
              ${warningInputNominal ? 'prize__new-card-input_warning' : ''}
            `}
          />
          <input
            value={cAmount}
            onKeyPress={(event) => {
              if (!(/[0-9]/.test(event.key) || event.key === ".")) {
                event.preventDefault();
              }
            }}
            onChange={(t) => {
              setCAmount(t.target.value);
              handleWarningInputQty(t.target.value);
            }}
            placeholder={"Кол-во"}
            className={`
              prize__new-card-input 
              prize__new-card-input_amount 
              ${warningInputQty ? 'prize__new-card-input_warning' : ''}
            `}
          />
          <div
            className="prize__new-card-button"
            onClick={handleClickAddNewItem}>
            <div className="prize__new-card-btn-title">Добавить</div>
            <img
              className="prize__new-card-icon"
              alt="img"
              src={require("../../../assets/icons/add_plus.png")}
            />
          </div>
        </div>
        <div className="prize__card-warning">
          <span className="prize__new-card-info">
            {item.step ? `шаг номинала кратный ${item.step + ' ' + listLang.symbol}` : 'можно выбрать любой номинал' }
          </span>
          <span className="prize__new-card-info">
            минимальный номинал {item.min_nominal + ' ' + listLang.symbol}{' '}
            максимальный {item.max_nominal + ' ' + listLang.symbol}
          </span>
        </div>
        <div>
          {
            warningInputNominal
              ? <b className="prize__warning">{getErrorMessage()}</b>
              : warningInputQty
                ? <b className="prize__warning">Номинал не может быть меньше единицы</b>
                : <br/>
          }
        </div>
      </div>
    </div>
  );
});

AddCardOpen.displayName = 'AddCardOpen';

export default AddCardOpen;