import prizesReducer from "./prizesReducer";
import userReducer from "./userReducer";
import ndflReducer from "./ndflReducer";
import cartReducer from "./cartReducer";
import ordersReducer from "./ordersReducer";
import listlangReducer from "./listlangReducer";
import settingsReducer from "./settingsReducer";
import orderListReducer from "./orderListReducer";

export default function rootReducer(state = {}, action) {
  return {
    prizes: prizesReducer(state.prizes, action),
    user: userReducer(state.user, action),
    ndfl: ndflReducer(state.ndfl, action),
    listLang: listlangReducer(state.listLang, action),
    settings: settingsReducer(state.settings, action),
    cart: cartReducer(state.cart, action),
    orders: ordersReducer(state.orders, action),
    ordersList: orderListReducer(state.ordersList, action),
  };
}
