import React, { memo } from "react";

const AddCard = memo(({item, listLang, handleClick}) => {
  return (
    <div className="prize__new-card-container" onClick={handleClick}>
      <img
        alt="img"
        src={require(`../../../assets/icons/add_card_${listLang.countrySymbol.toLocaleLowerCase()}.png`)}
        className="prize__new-card_add-icon"
      />
      <div>
        <p className="prize__new-card-title">Добавить карту со своим номиналом</p>
        <div className="prize__new-card-period">
          {item.validity_period_nominal}
        </div>
      </div>
    </div>
  );
});

AddCard.displayName = 'AddCard';

export default AddCard;