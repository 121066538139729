import React, { memo, useCallback } from "react";

import Description from "./Description";
import Rules from "./Rules";
import RulesText from "./RulesText";
import { openModal } from "../../../utils/helpers";

const ImageBlock = memo(({
  item,
  isMobile,
  scrollWidth,
  description,
  setVisibleRules,
  setVisibleConditions,
  setDescription
}) => {
  const handleClickOnModal = useCallback((setVisible) => {
    setVisible(true);
    openModal(scrollWidth);
  }, [scrollWidth]);

  if (isMobile) {
    return (
      <div className="prize__info-container">
        <div className="prize__info-content">
          <div className="prize__info-image-container">
            <img alt="img" className="prize__info-img" src={item.file_link} />
          </div>
          <div className="prize__info-details-container">
            <Rules handleClick={handleClickOnModal} setVisible={setVisibleRules} />
            {item.limitations ? (
              <RulesText 
                handleClick={handleClickOnModal} 
                setVisible={setVisibleConditions} 
              />
            ) : null}
          </div>
        </div>
        {item.description ? (
          <Description 
            item={item} 
            description={description} 
            setDescription={setDescription} 
          />
        ) : null}
      </div>
    );
  }
  return (
    <div className="prize__info-container">
      <div className="prize__info-image-container">
        <img alt="img" className="prize__info-img" src={item.file_link} />
      </div>
      <Rules handleClick={handleClickOnModal} setVisible={setVisibleRules} />
      {item.limitations ? (
        <RulesText 
          handleClick={handleClickOnModal} 
          setVisible={setVisibleConditions} 
        />
      ) : null}
      {item.description ? (
        <Description 
          item={item} 
          description={description} 
          setDescription={setDescription} 
        />
      ) : null}
    </div>
  );
});

ImageBlock.displayName = 'ImageBlock';

export default ImageBlock;