import React from "react";

import { formatNumber } from "../../../utils/helpers";

const PriceTotal = ({ status, quantity, nominal, price, symbol }) => (
  <b className={`order-list__price-total ${status === 'cancel' ? 'order-list__price-total_rejected' : ''}`}>
    {quantity && nominal
      ? `${quantity} x ${formatNumber(nominal)}`
      : formatNumber(price)
    } {symbol}
  </b>
);

export default PriceTotal;