import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ax from "../../utils/ax";

const initialState = {
  items: [],
};

export const postCreateOrder = createAsyncThunk(
  "basket/create-order",
  async () => {
    try {
      const {data} = await ax().post('basket/create-order', {})
      return data;
    } catch (error) {
      return error;
    }
  }
);

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    createOrder(state, action) {
      const { item } = action.payload
      state.items.push(item)
    },
  }
})

export const { createOrder } = ordersSlice.actions

export default ordersSlice.reducer
