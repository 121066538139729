import React from "react";
import { useSelector } from "react-redux";

import TopMenu from "../components/TopMenu/TopMenu";
import Header from "../components/Header/Header";
import NDFL from "../components/NDFL/NDFL";
import PhoneForm from "../components/PhoneForm/PhoneForm";

const Phone = () => {
  const ndfl = useSelector((state) => state.ndfl);

  return (
    <div>
      <Header />
      <TopMenu />
      {ndfl.status !== "confirmed" ? <NDFL /> : null}
      <PhoneForm />
    </div>
  );
};

export default Phone;
