export default {
  symbol: '₽',
  countrySymbol: 'RU',
  rusCountrySymbol: 'РФ',
  organization: 'Российские организации',
  fullCountryName: 'Российской Федерации',
  taxSystem: 'НДФЛ',
  individualNumberText: 'ИНН',
  maxPaymentBankText: <>14&nbsp;500&nbsp;</>,
  maxPaymentPhoneText: <>5&nbsp;000&nbsp;</>,
  maxPaymentSBPText: <>14&nbsp;500&nbsp;</>,
  maxPaymentBankValue: 14500,
  minPaymentBankValue: 2,
  maxPaymentPhoneValue: 5000,
  minPaymentPhoneValue: 2,
  maxPaymentSBPValue: 14500,
  minPaymentSBPValue: 2,
}