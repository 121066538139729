import { useDispatch } from 'react-redux';

import { ndflUpdate } from '../../actions/widgetActions';
import { renderStatus, renderStatusContent } from './index';
import Button from '../Button/Button';

import { ReactComponent as Warning } from '../../assets/iconSvg/warning-status.svg';

import "./NDFLStatus.css";

const NDFLStatus = ({
  status,
  setNdflStatus,
  ndflId,
  createdDate,
  comment,
  isHandle,
  setIsHandle,
  setIsAutoRecognize,
  isAutoRecognize
  }) => {
  const dispatch = useDispatch();

  const statusType = {
    confirmed: "Принята",
    moderation: "В обработке",
    rejected: "Не принята",
  };

  const listItems = {
    id: ndflId,
    date: createdDate,
    comment: comment,
  };

  const handleClickHandle = () => {
    setIsHandle(true)
  };

  const handleClickAutoRecognize = () => {
    setIsAutoRecognize(true);
    setIsHandle(false)

  };

  const hasValidStatus = status in statusType;
  const hasListItems = Object.values(listItems).some(item => item);

  return (
    <>
      <div className="ndfl-status__status-container">
        <h2 className="ndfl-status__status-title">Статус</h2>
        <div className='ndfl-status__message'>{renderStatus(status, statusType)}</div>
      </div>
    
      {hasValidStatus && hasListItems ? (
        <div className="ndfl-status__container">
          <div className="ndfl-status__content">
            {renderStatusContent(status, statusType, listItems)}
          </div>
        </div>
      ) : null}

      {(!status || status === 'rejected') && isHandle === null ? (
        <div className="ndfl-status__button-container">
          <Button
            customClass="ndfl-status__button"
            onClick={handleClickAutoRecognize}
            title="Распознать данные автоматически"
          />
          <Button
            customClass="ndfl-status__button ndfl-status__handle-edit"
            onClick={handleClickHandle}
            title="Заполнить данные вручную"
          />
        </div>
      ) : null}
      {status === 'confirmed' ? (
        <div className='ndfl-status__warning'>
            <div>
              <Warning className='ndfl-status__icon'/>
            </div>
            <p className="ndfl-status__message ndfl-status__warning-message">
              Если паспортные данные изменились и вам нужно изменить анкету просим связаться с нами по почте 
              <a className='ndfl-status__link-text'>support@vitrinapromo.ru</a>
            </p>
        </div>
      ) : null}
    </>
  )
};

export default NDFLStatus;