import { useSelector } from "react-redux";
import React from "react";

import GoBack from "../GoBack/GoBack";

import "./order_complete.css";

const OrderComplete = (props) => {
  const { items, total } = props;
  const user = useSelector((state) => state.user);
  const listLang = useSelector((state) => state.listLang);

  return (
    <div className="order-complete__background">
      <div className={'order-complete__container'}>
        <h2 className={'order-complete__title'}>
          Спасибо за покупку!
        </h2>

        <GoBack />

        <p className={'order-complete__email-container'}>
          На{" "}
          <a className={'order-complete__link'} href="email_fhrfsgs@mail.ru">
            {user.email}
          </a>{" "}
          высланы электронные подарочные сертификаты:
        </p>

        <div>
          <div className="order-complete__header-title">
            <div className="header-title"> </div>
            <div className="header-title__name">Наименование</div>
            <div className="header-title__time">Срок изготовления</div>
            <div className="header-title__nominal">Номинал</div>
            <div className="header-title__amount">Кол-во</div>
            <div className="header-title__total">Итого</div>
          </div>

          <div className={'order-complete__list'}>
            {items.map((item, id) => (
              <div key={id} className="order-complete__columns">
                <div className="order-complete__item-number">
                  <p className="order-complete__item-number-text">
                    {id + 1}.
                  </p>
                </div>
                <div className="order-complete__item-name">
                  <p className="order-complete__item-name-text">
                    <b className="order-complete__item-name-text_bold">
                      {item.data.name}
                    </b>
                  </p>
                </div>

                <p className="order-complete__item-time">
                  1 дней
                </p>
                <p className="order-complete__item-nominal">
                  {item.nominal} {listLang.symbol}
                </p>

                <div className="order-complete__item-amount">
                  <b className="order-complete__item-amount-text">
                    {item.quantity}
                  </b>
                </div>
                <b className="order-complete__item-total-text">
                  {user.isDemo 
                    ? (item.nominal + (item.nominal / 100 * 10)) * item.quantity 
                    : item.nominal * item.quantity + ' ' + listLang.symbol}
                </b>
              </div>
            ))}
          </div>
        </div>

        <p className={'order-complete__total'}>
          Общая сумма: <b className="order-complete__total_bold">{total + ' ' + listLang.symbol}</b>
        </p>
      </div>
    </div>
  );
};

export default OrderComplete;
