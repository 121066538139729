import { formatCreatedDate } from '../../utils/helpers';

import { ReactComponent as Approve } from '../../assets/iconSvg/approve.svg';
import { ReactComponent as Error } from '../../assets/iconSvg/error.svg';
import { ReactComponent as Warning } from '../../assets/iconSvg/warning-status.svg';
import { ReactComponent as New } from '../../assets/iconSvg/new_ndfl.svg';
import { ReactComponent as Moderation } from '../../assets/iconSvg/moderation.svg';

export function renderStatus(status, statusType) {
  switch(status) {
    case "confirmed": {
      return (
        <div className="ndfl-status">
          <Approve />
          <p className="ndfl-status__text ndfl-status__text-approve">{statusType[status]}</p>
        </div>
      );
    }
    case "moderation": {
      return (
        <div className="ndfl-status">
          <Moderation />
          <p className="ndfl-status__text">{statusType[status]}</p>
        </div>
      );
    }
    case "rejected": {
      return (
        <div className="ndfl-status">
          <Error />
          <p className="ndfl-status__text ndfl-status__text-reject">{statusType[status]}</p>
        </div>
      );
    }
    default: 
      return (
        <div className="ndfl-status">
          <New />
          <p className="ndfl-status__text ndfl-status__text-new">Не заполнена</p>
        </div>
      );
  }
}

export function renderStatusContent(status, statusType, listItems) {
  const listTitle = [
    {title: 'ID Анкеты', content: listItems.id},
    {title: 'Дата создания', content: formatCreatedDate(listItems.date)},
  ];

  return (
    <>
      {(status === 'confirmed' || status === 'moderation') ? (
        listTitle.map((item) => {
          return (
            <div key={item.title} className="ndfl-status__status-content">
              <h2 className="ndfl-status__title">{item.title}</h2>
              <p className='ndfl-status__message'>{item.content}</p>
            </div>
          )
        })
      ) : null}
      {(status === 'rejected' || status === 'confirmed' || status === 'moderation') ? (
        <div className="ndfl-status__status-content">
          <h2 className="ndfl-status__title">Комментарий к анкете</h2>
          <p className='ndfl-status__message'>{listItems.comment}</p>
        </div>
      ) : null}
    </>
  )
};