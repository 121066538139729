import React, { useEffect, useState } from "react";
import {useSelector} from "react-redux";

import TopMenu from "../components/TopMenu/TopMenu";
import Header from "../components/Header/Header";
import NDFL from "../components/NDFL/NDFL";
import BankCard from "../components/BankCard/BankCard";
import BankCardBY from "../components/BankCardBY/BankCardBY";

const Bank = () => {
  const [cardPage, setCardPage] = useState(<BankCard />);

  const lang = useSelector((state) => state.user.lang);
  const ndfl = useSelector((state) => state.ndfl);

  useEffect(() => {
    if (lang === 'by') {
      setCardPage(<BankCardBY />);
    } else {
      setCardPage(<BankCard />);
    }
  }, [lang]);

  return (
    <div>
      <Header />
      <TopMenu />
      {ndfl.status !== "confirmed" ? <NDFL /> : null}
      {cardPage}
    </div>
  );
};

export default Bank;
