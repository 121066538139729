import React, { memo, useState } from "react";

import CartItem from "../../../components/CartItem/CartItem";
import { isColumnShown } from "../../../utils/helpers";

const List = memo(({items}) => {
  const [selected, setSelected] = useState([]);
  const { showCommission, showDiscount } = isColumnShown(items);

  const getColumnWidths = () => {
    const baseWidths = {
      name: 30,
      nominal: 15,
      quantity: 15,
      total: 12
    };
    
    let remainingWidth = 30;
    
    if (showCommission) {
      baseWidths.commission = remainingWidth / (showDiscount ? 2.4 : 3.2);
    }
    
    if (showDiscount) {
      baseWidths.discount = remainingWidth / (showCommission ? 2.7 : 3);
    }

    if ((!showCommission || !showDiscount) && (showDiscount || showCommission)) {
      baseWidths.name += 3;
    }
    
    if (!showCommission && !showDiscount) {
      baseWidths.name += 8;
      baseWidths.total += 6;
    }
    
    return baseWidths;
  };

  const columnWidths = getColumnWidths();

  return (
    <div className="cart__list-container">
      <div className="cart__list-header">
        <div style={{ width: `${columnWidths.name}%` }}>Наименование</div>
        <div style={{ width: `${columnWidths.nominal}%`, textAlign: "center" }}>Номинал</div>
        {showCommission ? (
          <div style={{ width: `${columnWidths.commission}%`, textAlign: "center" }}>Комиссия</div>
        ) : null}
        <div style={{ width: `${columnWidths.quantity}%`, textAlign: "center" }}>Кол-во</div>
        <div
          style={{ width: `${columnWidths.total}%`, textAlign: "right", marginRight: "32px" }}
        >
          Итого
        </div>
      </div>

      {items.map((item, index) => (
        <CartItem
          key={index}
          setItems={() => {}}
          items={[...items]}
          index={index}
          selected={selected}
          setSelected={setSelected}
          item={item}
        />
      ))}
    </div>
  );
});

List.displayName = 'List';

export default List;