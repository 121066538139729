import React from "react";

import Status from "./Status";
import TypeContainer from "./TypeContainer";
import PriceTotal from "./PriceTotal";
import OrderStatus from "./OrderStatus";

const OrderItem = ({item, type, isMobile, listLang, orderTypeLabels}) => {
  const isPrize = type === "prize";
  
  const status = isPrize ? item.status_label : item.order_status;
  const price = isPrize ? item.nominal / 100 : item.requested_amount / 100;

  if (isMobile) {
    return (
      isPrize && item.items ? (
        item.items.map((eps, index) => (
          <div key={index} className="order-list__item-container">
            <div className="order-list__item-content">
              <div className="order-list__item-mobile-wrapper">
                <div 
                  className={`
                    order-list__order-number 
                    ${status === "cancel" ? 'order-list__order-number_rejected' : ''}
                  `}>
                  №{index + 1}
                </div>
                <TypeContainer 
                  status={status} 
                  type={type} 
                  name={eps.name} 
                  orderTypeLabels={orderTypeLabels} 
                />
              </div>
              <div className="order-list__mobile-total-container">
                <PriceTotal 
                  status={status} 
                  quantity={eps.quantity} 
                  nominal={eps.nominal} 
                  symbol={listLang.symbol} 
                />
              </div>
            </div>
            <OrderStatus status={status} />
          </div>
        ))
      ) : (
        <div className="order-list__item-container">
          <div className="order-list__item-content">
            <div className="order-list__item-mobile-wrapper">
              <div 
                className={`
                  order-list__order-number 
                  ${status === "cancel" ? 'order-list__order-number_rejected' : ''}
                `}>
                №{1}
              </div>
              <TypeContainer status={status} type={type} orderTypeLabels={orderTypeLabels} />
            </div>
            <div className="order-list__mobile-total-container">
              <PriceTotal status={status} price={price} symbol={listLang.symbol} />
            </div>
          </div>
          <OrderStatus status={status} />
        </div>
      )
    );
  }

  return (
    isPrize && item.items ? (
      item.items.map((eps, index) => (
        <div key={index} className="order-list__item-container">
          <div className="order-list__item-wrapper">
            <div 
              className={`
                order-list__order-number 
                ${status === "cancel" ? 'order-list__order-number_rejected' : ''}
              `}>
              №{index + 1}
            </div>
            <TypeContainer 
              status={status} 
              type={type} 
              name={eps.name} 
              orderTypeLabels={orderTypeLabels} 
            />
          </div>
          <div className="order-list__item-price_status">
            <div className="order-list__prize-item order_list__prize-status">
              <PriceTotal 
                status={status} 
                quantity={eps.quantity} 
                nominal={eps.nominal} 
                symbol={listLang.symbol} 
              />
              <Status status={status} />
            </div>
          </div>
        </div>
      ))
    ) : (
      <div className="order-list__item-container">
        <div className="order-list__item-wrapper">
          <div 
            className={`
              order-list__order-number 
              ${status === "cancel" ? 'order-list__order-number_rejected' : ''}
            `}>
            №{1}
          </div>
          <TypeContainer status={status} type={type} orderTypeLabels={orderTypeLabels} />
        </div>
        <div className="order-list__item-price_status">
          <PriceTotal status={status} price={price} symbol={listLang.symbol} />
          <Status status={status} />
        </div>
      </div>
    )
  );
};

export default OrderItem;


