import React, {useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import NDFLModal from "../NDFLModal/NDFLModal";
import { getScrollbarWidth, openModal } from "../../utils/helpers";

import {ReactComponent as Warning} from "../../assets/icons/warning-circle.svg";

import "./ndflWarning.css";

const NDFLFormWarning = () => {
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);
  const listLang = useSelector((state) => state.listLang);
  const lang = useSelector((state) => state.user.lang);

  const [visibleInfoToNDFL, setVisibleInfoToNDFL] = useState(false);
  const scrollWidth = getScrollbarWidth();
  const isLang = lang === 'by' || lang === 'kz';

  if (user.isDemo) {
    return null;
  }

  const handleClickLink = () => {
    setVisibleInfoToNDFL(true);
    openModal(scrollWidth);
  };

  return (
    <div className="ndfl-warning__container">
      <div >
        <Warning className="ndfl-warning__icon"/>
      </div>
      <div>
        <p className="ndfl-warning__text">
          Для заказа подарка необходимо заполнить все поля{" "}
          Анкеты {listLang?.taxSystem}
          . Это нужно, чтобы мы заплатили за Вас налог на доходы физических лиц в
          соответствии с законодательством {listLang?.fullCountryName}.
        </p>
        <p className="ndfl-warning__link">
          <a
            onClick={handleClickLink}
            className="ndfl-warning__link-text">
            Что такое {listLang?.taxSystem} и почему его нужно оплатить?
          </a>
        </p>
      </div>

      {visibleInfoToNDFL && <NDFLModal lang={lang} listLang={listLang} setVisibleInfoToNDFL={setVisibleInfoToNDFL} />}
    </div>
  );
};

export default NDFLFormWarning;
