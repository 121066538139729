import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ax from "../../utils/ax";

const initialState = {
  items: [],
};

export const postCreatePayment = createAsyncThunk(
  "payment/create",
  async (paymentData) => {
    try {
      const {data} = await ax().post('payment/create', paymentData)
      return data;
    } catch (error) {
      return error;
    }
  }
);

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    createPayment(state, action) {
      const { item } = action.payload
      state.items.push(item)
    },
  }
})

export const { createPayment } = paymentSlice.actions

export default paymentSlice.reducer
