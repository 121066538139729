import React, { memo } from "react";
import { useDispatch } from "react-redux";

import { clearCart } from "../../../actions/widgetActions";

import { ReactComponent as Trash } from '../../../assets/iconSvg/trash.svg';

const ClearCart = memo(() => {
  const dispatch = useDispatch();

  return (
    <div className="cart__trash-container">
      <div className="cart__trash_delete-all" onClick={() => dispatch(clearCart())}>
        <Trash style={{marginRight: 8}} />
        Очистить корзину
      </div>
    </div>
  );
});

ClearCart.displayName = 'ClearCart';

export default ClearCart;