import React from "react";

import Status from "./Status";

const OrderStatus = ({status}) => {
  return (
    <div className="order-list__item-price_status">
      <span className="order-list__status-order-text">
        Статус заказа
      </span>
      <Status status={status} />
    </div>
  )
}

export default OrderStatus;