import {useDispatch, useSelector} from "react-redux";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { deleteFromCart, cartPlus, cartMinus } from "../../actions/widgetActions";
import { calculateTotalSum, isColumnShown } from "../../utils/helpers";

import "./cart_item.css";

const CartItem = (props) => {
  const { item, selected, setSelected, index, items } = props;
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const listLang = useSelector((state) => state.listLang);

  const isMobile = useMediaQuery({ maxWidth: 768 });

  const { showCommission, showDiscount } = isColumnShown(items);
  
  const { name, nominal, id, quantity, user_discount, user_markup } = item;

  const deleteItemFromCart = () => {
    dispatch(deleteFromCart({ item }));
    setSelected(selected.filter((i) => i !== id));
  };

  const displayName = name?.trim() || item.data.name?.trim();

  if (isMobile) {
    return (
      <div key={id} className="cart-item__container">
        <div className="cart-item__id">
          <p className="cart-item__value">
            <br />
            {index + 1}. ЭПС <b className="cart-item__name">«{displayName}»</b>
          </p>

          <div
            onClick={deleteItemFromCart}
            className="cart-item__delete"
          >
            <img
              alt="img"
              className="cart-item__delete-image"
              src={require("../../assets/icons/delete.png")}
            />
          </div>
        </div>

        <div>
          <div className="cart-item__content-item">
            <p className="cart-item__price-container">
              <div className="cart-item__price-value">Номинал</div>
              {nominal + ' ' + listLang.symbol}
            </p>
            {showCommission ? (
              <p className="cart-item__price-container">
                {user_markup > 0 ? (
                  <>
                    <div className="cart-item__price-value">Комиссия</div>
                    {user_markup + '%'}
                  </>
                ) : ''}
              </p>
            ) : null}
            <div className="cart-item__amount-wrapper">
              <div className="cart-item__amount-title">Кол-во</div>
              <div className="cart-item__amount-container">
                <img
                  alt="img"
                  src={require("../../assets/icons/minus.png")}
                  className="cart-item__amount-button"
                  onClick={() => dispatch(cartMinus({ item }))}
                />
                <b className="cart-item__value"> {quantity}</b>
                <img
                  alt="img"
                  src={require("../../assets/icons/plus.png")}
                  className="cart-item__amount-button"
                  onClick={() => {
                    dispatch(cartPlus({ item }));
                  }}
                />
              </div>
            </div>
            <p className="cart-item__total-price">
              <div className="cart-item__total-title">
                Итого
              </div>
              {calculateTotalSum(item) + ' ' + listLang.symbol}
            </p>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div key={id} className="cart-item__container">
      <div className="cart-item__id">
        <p className="cart-item__value">{index + 1}.</p>
      </div>

      <div className="cart-item__name-container">
        <p>
          ЭПС <b className="cart-item__name">«{displayName}»</b>
        </p>
      </div>

      <p className="cart-item__item-price">{nominal + ' ' + listLang.symbol}</p>

      {showCommission ? (
        <p className="cart-item__item-price">{user_markup > 0 ? user_markup + '%' : ''}</p>
      ) : null}

      <div className="cart-item__amount-container">
        <img
          alt="img"
          src={require("../../assets/icons/minus.png")}
          className="cart-item__amount-button"
          onClick={() => {
            dispatch(cartMinus({ item }));
          }}
        />
        <b className="cart-item__value"> {quantity}</b>
        <img
          alt="img"
          src={require("../../assets/icons/plus.png")}
          className="cart-item__amount-button"
          onClick={() => {
            dispatch(cartPlus({ item }));
          }}
        />
      </div>

      <b className="cart-item__total-price">
        {calculateTotalSum(item) + ' ' + listLang.symbol}
      </b>

      <div
        onClick={deleteItemFromCart}
        className="cart-item__delete"
      >
        <img
          alt="img"
          className="cart-item__delete-image"
          src={require("../../assets/icons/delete.png")}
        />
      </div>
    </div>
  );
};

export default CartItem;
